import {
  Calendar,
  DateRangeType,
  DayOfWeek,
  DefaultButton,
  IButtonStyles,
} from '@fluentui/react';
import * as React from 'react';
import {useCallback, useMemo} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {colors} from '../colors';
import {ResourceDetails} from '../types/ResourceDetails';
import {calendarStringsJa} from './common/dateutil';
import {TimeRangeWidget} from './TimeRangeWidget';

type Props = {
  value: {
    start?: Date;
    end?: Date;
  };
  onSelectTimeRange: (start: Date, end: Date) => void;
  events: ResourceDetails[];
  startAccessor: string;
  endAccessor: string;
};

export function CalendarWidget(props: Props): JSX.Element | null {
  const intl = useIntl();
  const [selectedDate, setSelectedDate] = React.useState<Date>();

  const onSelectDate = useCallback(
    (date: Date): void => {
      setSelectedDate(date);
    },
    [setSelectedDate],
  );

  const onDismiss = (): void => {
    // return selectedDate;
  };

  const onSelectMonthYear = useCallback((date: Date) => {
    setSelectedDate(date);
  }, []);

  const calendarMonthProps = useMemo(() => {
    return {
      onNavigateDate: onSelectMonthYear,
    };
  }, [onSelectMonthYear]);

  const showEventsMark = useCallback(
    (element: HTMLElement, date: Date) => {
      if (
        element &&
        contains(props.events, date, props.startAccessor, props.endAccessor)
      ) {
        element.classList.add('calendar-widget-has-event');
      }
    },
    [props.events, props.startAccessor, props.endAccessor],
  );

  return (
    <Container>
      <div style={{display: 'flex'}}>
        <Calendar
          onSelectDate={onSelectDate}
          onDismiss={onDismiss}
          dateRangeType={DateRangeType.Week}
          value={selectedDate!}
          firstDayOfWeek={DayOfWeek.Sunday}
          strings={calendarStringsJa}
          highlightCurrentMonth={true}
          highlightSelectedMonth={true}
          isDayPickerVisible={true}
          isMonthPickerVisible={true}
          showMonthPickerAsOverlay={false}
          showGoToToday={false}
          showWeekNumbers={false}
          showSixWeeksByDefault={true}
          styles={{root: {width: 'auto'}}}
          calendarDayProps={{
            className: 'calendar-widget',
            customDayCellRef: showEventsMark,
          }}
          calendarMonthProps={calendarMonthProps}
        />
        <DefaultButton
          onClick={() => {
            setSelectedDate(new Date());
          }}
          styles={goToTodayButtonStyles}
          text={intl.formatMessage({id: 'Widget.Calendar.Today'})}
        />
      </div>
      <TimeRangeWidget
        week={selectedDate}
        height={'400px'}
        value={props.value}
        events={props.events}
        onSelectTimeRange={props.onSelectTimeRange}
        startAccessor={props.startAccessor}
        endAccessor={props.endAccessor}
      />
    </Container>
  );
}

function contains(
  events: ResourceDetails[],
  date: Date,
  start: string,
  end: string,
): boolean {
  const d = ymd(date);

  for (let event of events) {
    const s = ymd(new Date(event[start]));
    const e = ymd(new Date(event[end]));

    if (s <= d && d <= e) {
      return true;
    }
  }

  return false;
}

function ymd(date: Date): number {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return y * 1000 + m * 100 + d;
}

const unitSize = 28;
const buttonSize = unitSize - 4;
const dotSize = 3;

const Container = styled.div`
  & .calendar-widget {
    width: ${unitSize * 7}px;
  }

  & .calendar-widget td,
  & .calendar-widget th {
    position: relative;
    width: ${unitSize}px;
    height: ${unitSize}px;
  }

  & .calendar-widget td button {
    width: ${buttonSize}px;
    height: ${buttonSize}px;
  }

  & .calendar-widget-has-event::after {
    position: absolute;
    bottom: 3px;
    left: ${Math.floor((unitSize - dotSize) / 2)}px;
    content: '';
    width: ${dotSize}px;
    height: ${dotSize}px;
    background-color: ${colors.neutralTertiary};
    border: 1px solid transparent;
    border-radius: 50%;
  }
`;

const goToTodayButtonStyles: IButtonStyles = {
  root: {
    fontSize: '0.7rem',
    alignSelf: 'flex-end',
    width: '3rem',
    minWidth: '3rem',
    padding: 0,
    marginLeft: '-60px',
    marginBottom: '20px',
    border: 'none',
  },
  label: {
    fontWeight: 'normal',
  },
};
