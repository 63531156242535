import React from 'react';
import {ButtonWidget} from '../ButtonWidget';
import {ScanData} from './ScanData';
import {ButtonContainer} from './UsersAndThings';

type Props = {
  data: ScanData;
  size: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  actions: Action[];
};

export type Action = {
  label: string;
  execute: () => void;
};

export type ActionDef = {
  label: string;
  value: string;
};

export function ActionButtons(props: Props): JSX.Element | null {
  const buttons = props.actions.map((action, i) => (
    <Button key={`action-button-${i}`} {...props} action={action} />
  ));

  return <>{buttons}</>;
}

type ButtonProps = {
  data: ScanData;
  size: number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  action: Action;
};

function Button(props: ButtonProps): JSX.Element | null {
  return (
    <ButtonContainer>
      <ButtonWidget
        onClick={props.action.execute}
        label={props.action.label}
        size={props.size}
        width={props.width}
        minWidth={props.minWidth}
        maxWidth={props.maxWidth}
        primary={true}
        disabled={!props.data.canSendRequest()}
        styles={{
          button: {flexGrow: 1},
        }}
      />
    </ButtonContainer>
  );
}
