import React from 'react';
import {OnChangeFieldValue} from '../field/FieldValue';
import {useMessage} from '../hooks/useMessage';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {Params} from '../types/Params';
import {RelatedResources, RelatedSchemas} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {Steps} from '../widgets/item/Step';
import {ItemCheckboxWidget} from '../widgets/ItemCheckboxWidget';

type Props = {
  value: any;
  values: FormValues;
  onChange: OnChangeFieldValue;
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
  relatedResources: RelatedResources;
  relatedSchemas: RelatedSchemas;
};

export function ItemCheckboxField(props: Props): JSX.Element | null {
  const title = useMessage('Widget.Item.Select', 'Select');
  const candidateTitle = widgetProps<string>(
    props.field,
    'candidate_title',
    '',
  );
  const tree = widgetProps<boolean>(props.field, 'tree', false);
  const expand = widgetProps<boolean>(props.field, 'expand', false);
  const steps = widgetProps<Steps>(props.field, 'steps', []);
  const defaultParams = widgetProps<Params>(props.field, 'params', {});
  const nameFieldId = widgetProps<string>(props.field, 'name_field_id', '');
  const checkFieldId = widgetProps<string>(
    props.field,
    'checkbox_field_id',
    '',
  );
  const checkboxDescription = widgetProps<string>(
    props.field,
    'checkbox_description',
    '',
  );
  const checkboxDescriptionView = widgetProps<string>(
    props.field,
    'checkbox_description_view',
    '',
  );

  const relatedResources = props.relatedResources;

  const itemType = props.field.itemType;
  const itemSubTypes = props.field.itemSubTypes;
  const itemTreeSubTypes = props.field.itemTreeSubTypes;

  if (!itemType || !checkFieldId) {
    return null;
  }

  const itemSchema = props.relatedSchemas[itemType || ''];
  const disabledIds = [props.item.id];

  const onChange = (ids: string[]) => {
    props.onChange({[props.field.id]: ids});
  };

  const onCheck = (ids: string[]) => {
    props.onChange({[checkFieldId]: ids});
  };

  const onClear = () => {
    props.onChange({[props.field.id]: [], [checkFieldId]: []});
  };

  const checked: string[] = array(props.values[checkFieldId]) || [];

  const p = {
    title,
    candidateTitle,
    tree,
    expand,
    steps,
    defaultParams,
    nameFieldId,
    relatedResources,
    itemType,
    itemSubTypes,
    itemTreeSubTypes,
    itemSchema,
    disabledIds,
    checkboxDescription,
    checkboxDescriptionView,
    onChange,
    checked,
    onCheck,
    onClear,
  };

  return <ItemCheckboxWidget {...props} {...p} />;
}
