import {Persona, PersonaSize} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {api} from '../api';

export function CurrentUser(): JSX.Element {
  const user = api.getCurrentUser();
  return <CurrentUserWithFetch id={user.id} name={user.name} />;
}

type Props = {
  id: string;
  name: string;
};

function CurrentUserWithFetch({id, name}: Props): JSX.Element {
  const [iconColor, setIconColor] = useState<string>();

  useEffect(() => {
    const ctx = api.newContext();

    (async () => {
      const user = await api.show(ctx, 'user', id);
      setIconColor(user.details.icon_color || user.schema.iconColor);
    })();

    return () => {
      ctx.abort();
    };
  }, [id]);

  const personaProps = iconColor ? {initialsColor: iconColor} : null;

  return <Persona text={name} size={PersonaSize.size24} {...personaProps} />;
}
