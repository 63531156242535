import {Dropdown, IDropdownOption, PrimaryButton} from '@fluentui/react';
import React, {ChangeEvent, useEffect, useState} from 'react';
import styled from 'styled-components';
import {api, ApiContext, useApiContext} from '../api';
import {ErrorMsg} from '../common/ErrorMsg';

export function SystemScreen(): JSX.Element {
  const ctx: ApiContext = useApiContext();
  const menuList = useApps(ctx);

  return (
    <Container>
      <ImportForm apps={menuList} ctx={ctx} />
    </Container>
  );
}

type App = {
  id: string;
};

function useApps(ctx: ApiContext | null): App[] {
  const [apps, setApps] = useState<App[]>([]);

  useEffect(() => {
    (async () => {
      if (!ctx) {
        return;
      }

      const apps = await api.apps(ctx);

      setApps(apps);
    })();
  }, [ctx]);

  return apps;
}

type ImportFormProps = {
  apps: App[];
  ctx: ApiContext;
};

type Status = 'init' | 'done' | 'error';

function ImportForm({apps, ctx}: ImportFormProps) {
  const [app, setApp] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [status, setStatus] = useState<Status>('init');

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = convertToFiles(e.target.files);
    setFiles(files);
  };

  const onSubmit = async () => {
    try {
      await api.postFormData(ctx, `/sys/import/${app}`, {}, {file: files});
      setStatus('done');
    } catch (e) {
      setStatus('error');

      if (e instanceof Error) {
        setError(e);
      }
    }
  };

  const options = apps.map((a) => ({
    key: a.id,
    text: a.id,
    selected: a.id === app,
  }));

  return (
    <FormContainer>
      <FormTitle>Import</FormTitle>
      <AppSelector value={app} options={options} onChange={setApp} />
      <FileSelector
        type={'file'}
        multiple={false}
        onChange={onChangeFile}
        accept={'.csv'}
      />
      <Message status={status} error={error} />
      <PrimaryButton
        onClick={onSubmit}
        text={'Import'}
        styles={{root: {display: 'block'}}}
      />
    </FormContainer>
  );
}

const convertToFiles = (fileList: FileList | null): File[] => {
  if (!fileList) {
    return [];
  }

  const files: File[] = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    if (file) {
      files.push(file);
    }
  }

  return files;
};

type AppSelectorProps = {
  value: string;
  options: IDropdownOption[];
  onChange: (v: string) => void;
};

function AppSelector({value, options, onChange}: AppSelectorProps) {
  return (
    <Dropdown
      styles={{
        root: {
          marginBottom: '1rem',
        },
        dropdown: {
          width: 300,
        },
      }}
      selectedKey={value}
      options={options}
      onChange={(e, opt) => {
        if (opt) {
          onChange(String(opt.key));
        }
      }}
    />
  );
}

type MessageProps = {
  status: Status;
  error: Error | null;
};

function Message({status, error}: MessageProps) {
  switch (status) {
    case 'init':
      return null;
    case 'done':
      return <SuccessMessage>Done</SuccessMessage>;
  }

  const msg = error ? error.message : 'Error';
  return <ErrorMsg messages={[msg]} />;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormContainer = styled.div`
  padding: 1rem;
`;

const FormTitle = styled.div`
  margin-bottom: 1rem;
`;

const FileSelector = styled.input`
  width: 300px;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.div`
  margin-bottom: 1rem;
`;
