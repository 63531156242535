import React, {useEffect, useRef, useState} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import styled from 'styled-components';
import {api, ApiContext} from '../../api';
import {selectComponent} from '../../components/Components';
import {ItemDetails, ItemDetailsProps} from '../../components/ItemDetails';
import {ItemForm, RelatedValues, Values} from '../../components/ItemForm';
import {PARAM_KEY_CHECKED_IDS} from '../../consts';
import {Actions} from '../../types/Action';
import {FormFiles} from '../../types/Form';
import {Params} from '../../types/Params';
import {Resource} from '../../types/Resource';
import {Section} from '../../types/Section';
import {Dialog} from '../Dialog';
import {extractSections} from '../Schema';

type Props = {
  ctx: ApiContext;
  shown: boolean;
  appId: string;
  resId?: string;
  isNew?: boolean;
  params?: Params;
  title?: string;
  checkedIds: Set<string>;
  onAfter: (res: Resource) => void;
  onClose: () => void;
  actions: Actions;
};

export function ItemFormDialog(props: Props): JSX.Element | null {
  const ref = useRef<Dialog>(null);
  const [res, setRes] = useState<Resource | null>(null);
  const [done, setDone] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    if (ref && ref.current && props.shown) {
      ref.current.showDialog();
    }
  }, [ref, props.shown]);

  const title = props.title || getDefaultTitle(intl, props.isNew);

  const onOpen = async () => {
    try {
      if (props.isNew) {
        const res = await api.newByPost(props.ctx, props.appId, {
          ...props.params,
          [PARAM_KEY_CHECKED_IDS]: [...props.checkedIds],
        });
        setRes(res);
        return;
      }

      if (props.resId) {
        const res = await api.edit(props.ctx, props.appId, props.resId);
        setRes(res);
        return;
      }
    } catch (e) {}
  };

  const onClose = () => {
    setRes(null);
    setDone(false);
    props.onClose();
  };

  const close = () => {
    onClose();

    if (ref && ref.current) {
      ref.current.closeDialog();
    }
  };

  const onSave = async (
    values: Values,
    files: FormFiles,
    relatedValues: RelatedValues,
  ) => {
    if (props.isNew) {
      const res = await api.create(
        props.ctx,
        props.appId,
        values,
        files,
        relatedValues,
      );
      props.onAfter(res);
      setRes(res);
      setDone(true);
      return;
    }

    if (props.resId) {
      const res = await api.update(
        props.ctx,
        props.appId,
        props.resId,
        values,
        files,
        relatedValues,
      );
      props.onAfter(res);
      setRes(res);
      setDone(true);
      return;
    }
  };

  const contents = res ? (
    done ? (
      <ItemDetails resource={res} actions={{}} />
    ) : (
      <ItemForm
        ctx={props.ctx}
        resource={res}
        onCancel={close}
        onSave={onSave}
        actions={props.actions}
        labelPosition={getLabelPosition(res)}
        sections={getSections(res)}
      />
    )
  ) : null;

  return (
    <Dialog
      ref={ref}
      modal={!done}
      title={title}
      maxWidth="100%"
      onOpen={onOpen}
      onClose={onClose}>
      <Container>{contents}</Container>
    </Dialog>
  );
}

function getDefaultTitle(intl: IntlShape, isNew?: boolean): string {
  if (isNew) {
    return intl.formatMessage({id: 'Action.New', defaultMessage: 'New'});
  }

  return intl.formatMessage({id: 'Action.Edit', defaultMessage: 'Edit'});
}

function getLabelPosition(res: Resource | null): string | undefined {
  if (!res) {
    return;
  }

  const props = selectComponent<ItemDetailsProps>(
    res.schema.screen.components,
    'item',
  );

  if (!props) {
    return;
  }

  return props.labelPosition;
}

function getSections(res: Resource | null): Section[] | undefined {
  if (!res) {
    return;
  }

  return extractSections(res.schema);
}

const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 600px;
`;
