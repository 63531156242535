import {DefaultButton, IButtonProps, mergeStyleSets} from '@fluentui/react';
import React from 'react';
import {useIntl} from 'react-intl';

export function ClearButton(props: IButtonProps): JSX.Element {
  const intl = useIntl();

  return (
    <DefaultButton
      text={intl.formatMessage({id: 'Action.Clear'})}
      onClick={props.onClick}
      styles={mergeStyleSets(props.styles, {root: {whiteSpace: 'nowrap'}})}
    />
  );
}
