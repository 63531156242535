import {DefaultButton} from '@fluentui/react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {useMessage} from '../hooks/useMessage';
import {MaterialIcon} from '../icons/MaterialIcon';
import {TextInputWidget} from '../widgets/TextWidget';

type Props = {
  onSearch: (query: string) => void;
};

export function SearchBox(props: Props): JSX.Element | null {
  const [query, setQuery] = useState<string>();
  const label = useMessage('Action.Search', 'Search');
  const search = () => {
    if (query) {
      props.onSearch(query);
    }
  };

  return (
    <Container>
      <TextInputWidget
        value={query}
        onChange={setQuery}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            search();
          }
        }}
        multiline={false}
        textProps={{
          onRenderPrefix: () => {
            return <MaterialIcon iconName={'search'} />;
          },
          styles: {
            field: {
              paddingLeft: 0,
            },
            prefix: {
              background: 'white',
            },
          },
        }}
      />
      <DefaultButton text={label} onClick={search} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;
