import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {ApiContext, useApiContext} from '../../api';
import {Params} from '../../types/Params';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceListForTable} from '../../types/ResourceList';
import {SchemaWithoutScreen} from '../../types/Schema';
import {ColumnContainer, Layout, SelectedColumn} from './elements';
import {FilterAndCandidates} from './FilterAndCandidates';
import {SelectedItems} from './SelectedItems';
import {Steps} from './Step';

export type ItemSelectorProps = {
  itemSchema: SchemaWithoutScreen;
  itemType: string;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  disabledIds: string[];
  capacity?: number;
  nameFieldId: string;
  candidateTitle: string;
  tree: boolean;
  expand: boolean;
  steps: Steps;
  defaultParams: Params; // widgetProps(params)
  style?: CSSProperties;
};

type Props = ItemSelectorProps & {
  selected: ResourceDetails[];
  onSelect: (selected: ResourceDetails[]) => void;
};

export function ItemSelector(props: Props): JSX.Element {
  const ctx = useApiContext();

  return (
    <Container style={props.style}>
      <Layout>
        <FilterAndCandidates
          {...props}
          ctx={ctx}
          onSelect={(items) => {
            onClickCandidate(ctx, items, props);
          }}
          onSelectAll={(items) => {
            props.onSelect(items);
          }}
        />
        <SelectedColumn>
          <ColumnContainer>
            <SelectedItems
              {...props}
              ctx={ctx}
              list={resList(props.selected)}
              onSelect={(item) => {
                onClickSelected(ctx, [item], props);
              }}
              onSelectAll={() => {
                props.onSelect([]);
              }}
              onUpdateList={(list) => {
                props.onSelect(list.list);
              }}
            />
          </ColumnContainer>
        </SelectedColumn>
      </Layout>
    </Container>
  );
}

function onClickCandidate(
  ctx: ApiContext,
  items: ResourceDetails[],
  props: Props,
) {
  const {add, delIds} = categorize(items, props.selected);

  let newSelected = [...props.selected]
    .filter((item) => !delIds.has(item.id))
    .concat(add);

  if (props.capacity && newSelected.length > props.capacity) {
    newSelected = newSelected.slice(newSelected.length - props.capacity);
  }

  props.onSelect(newSelected);
}

function categorize(
  items: ResourceDetails[],
  selected: ResourceDetails[],
): {add: ResourceDetails[]; delIds: Set<string>} {
  const add: ResourceDetails[] = [];
  const delIds = new Set<string>();

  const ids = new Set<string>(selected.map((x) => x.id));

  for (let item of items) {
    if (ids.has(item.id)) {
      delIds.add(item.id);
    } else {
      add.push(item);
    }
  }

  return {add, delIds};
}

function onClickSelected(
  ctx: ApiContext,
  items: ResourceDetails[],
  props: Props,
): void {
  const selected = [...props.selected];
  const indexes = indexesToRemove(items, selected);

  for (let index of indexes) {
    selected.splice(index, 1);
  }

  props.onSelect(selected);
}

function indexesToRemove(
  items: ResourceDetails[],
  selected: ResourceDetails[],
): number[] {
  const nums: number[] = [];

  for (let item of items) {
    const i = selected.findIndex((elem) => item.id === elem.id);

    if (i >= 0) {
      nums.push(i);
    }
  }

  return nums.sort().reverse();
}

function resList(items: ResourceDetails[]): ResourceListForTable {
  return {
    list: items,
    count: 0,
    currentPage: 0,
    maxPage: 0,
    pageSize: 0,
    params: {},
    timestamp: String(new Date().getTime()),
  };
}

const Container = styled.div``;
