import React, {CSSProperties, MouseEvent} from 'react';
import styled from 'styled-components';
import {Query} from '../../api';
import {diffDays, getDateText} from '../../dates/dates';
import {
  bodyColorPrimary,
  borderColorLight,
  textColorInverse,
} from '../../styles';
import {ResourceDetails} from '../../types/ResourceDetails';
import {array} from '../../util';
import {toDates} from '../../widgets/common/dateutil';
import {DateRange, newDate} from '../DateRange';

export type onClickEventFn = (
  schemaId: string,
  resId: string,
  query?: Query,
) => void;

type EventBarsProps = {
  events: ResourceDetails[];
  dateRange: DateRange;
  unitWidth: number;
  onClickEvent?: onClickEventFn;
  allowAdjacent?: boolean;
};

export function EventBars(props: EventBarsProps): JSX.Element | null {
  if (props.events.length === 0) {
    return null;
  }

  const width = props.dateRange.len * props.unitWidth;

  return (
    <Container style={{width}}>
      <Events {...props} />
    </Container>
  );
}

function Events(props: EventBarsProps): JSX.Element {
  const slots: Slot[][] = [];
  const allowAdjacent = props.allowAdjacent || false;

  for (let event of props.events) {
    addSlot(slots, event, props.dateRange, allowAdjacent);
  }

  let prevEnd: number = 0;

  const fn = (e: MouseEvent<HTMLDivElement>, slot: Slot) => {
    if (props.onClickEvent) {
      props.onClickEvent(slot.schemaId, slot.id);
    }

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      {slots.map((row, i) => {
        prevEnd = 0;

        return (
          <Row key={`row-${i}`}>
            {row.map((slot, j) => {
              const start = slot.start * props.unitWidth;
              const marginLeft = start - prevEnd;
              const width = slot.len * props.unitWidth;
              prevEnd = start + width;
              const style = {marginLeft, width, ...slot.style};

              let subPrevEnd: number = 0;

              return (
                <Bar
                  key={`bar-${j}`}
                  style={style}
                  onClick={(e) => {
                    fn(e, slot);
                  }}>
                  {slot.subSlots().map((slot, j) => {
                    const start = slot.start * props.unitWidth;
                    const marginLeft = start - subPrevEnd;
                    const width = slot.len * props.unitWidth;
                    subPrevEnd = start + width;
                    const style = {marginLeft, width, ...slot.style};

                    return (
                      <SubBar
                        key={`sub-bar-${j}`}
                        style={style}
                        title={slot.text}
                        onClick={(e) => {
                          fn(e, slot);
                        }}>
                        {slot.text}
                      </SubBar>
                    );
                  })}
                </Bar>
              );
            })}
          </Row>
        );
      })}
    </>
  );
}

function addSlot(
  slots: Slot[][],
  event: ResourceDetails,
  dateRange: DateRange,
  allowAdjacent: boolean,
) {
  const slot = Slot.fromEvent(event, dateRange);

  for (let row of slots) {
    if (canAdd(row, slot, allowAdjacent)) {
      row.push(slot);
      return;
    }
  }

  slots.push([slot]);
}

function canAdd(row: Slot[], slot: Slot, allowAdjacent: boolean): boolean {
  const adjust = allowAdjacent ? 0 : 1;

  for (let s of row) {
    // `end + 1 (when adjust = 1)` to prevent them from being right next to each other
    if (s.end + adjust > slot.start && slot.end + adjust > s.start) {
      return false;
    }
  }

  return true;
}

class Slot {
  readonly schemaId: string;
  readonly id: string;
  readonly start: number;
  readonly end: number;
  readonly len: number; // (end - start)
  readonly text: string;
  readonly style: CSSProperties;

  private readonly startDate: Date;
  private readonly endDate: Date;
  private readonly event: ResourceDetails;
  private readonly dateRange: DateRange;

  static fromEvent(event: ResourceDetails, dateRange: DateRange): Slot {
    const s = newDate(event['start_date']);
    const e = newDate(event['end_date']);
    const {startDate, endDate} = decideStartEnd(dateRange, s, e);
    const {start, end} = convertToNumInCalendar(startDate, endDate, dateRange);
    const {text, style} = selectTextAndStyle(event, '');

    return new Slot(
      event.schema_id,
      event.id,
      start,
      end,
      text,
      style,
      startDate,
      endDate,
      event,
      dateRange,
    );
  }

  constructor(
    schemaId: string,
    id: string,
    start: number,
    end: number,
    text: string,
    style: CSSProperties,

    startDate: Date,
    endDate: Date,
    event: ResourceDetails,
    dateRange: DateRange,
  ) {
    this.schemaId = schemaId;
    this.id = id;
    this.start = start;
    this.end = end;
    this.len = end - start;
    this.text = text;
    this.style = style;

    this.startDate = startDate;
    this.endDate = endDate;
    this.event = event;
    this.dateRange = dateRange;
  }

  subSlots(): Slot[] {
    const keys = array(this.event[modKeys]);

    if (keys.length === 0) {
      return [];
    }

    const splitPoints: SplitPoint[] = [];

    for (let fieldId of keys) {
      const dates = toDates(this.event[fieldId]);

      for (let date of dates) {
        const diffS = diffDays(date, this.dateRange.start);
        const diffE = diffDays(this.dateRange.end, date);

        if (diffS < 0 || diffE < 0) {
          continue;
        }

        const diff = diffDays(date, this.startDate) - 1;
        splitPoints.push({
          startDiff: diff,
          len: 1,
          date: date,
        });
      }
    }

    if (splitPoints.length === 0) {
      return [];
    }

    const slots: Slot[] = [];

    let base: Slot = this;

    for (let point of splitPoints) {
      slots.push(base.newSubSlot(point.startDiff, point.len, point.date));
    }

    return slots;
  }

  newSubSlot(start: number, len: number, date?: Date): Slot {
    const {text, style} = selectTextAndStyle(this.event, getDateText(date));

    return new Slot(
      this.schemaId,
      this.id,
      start,
      start + len,
      text,
      style,
      this.startDate,
      this.endDate,
      this.event,
      this.dateRange,
    );
  }
}

type SplitPoint = {
  startDiff: number;
  len: number;
  date?: Date;
};

function decideStartEnd(
  dateRange: DateRange,
  start: Date | null,
  end: Date | null,
): {startDate: Date; endDate: Date} {
  const s = dateRange.contains(start);
  const e = dateRange.contains(end);

  if (s && e) {
    return {
      startDate: start!,
      endDate: end!,
    };
  }

  if (s) {
    return {
      startDate: start!,
      endDate: dateRange.end,
    };
  }

  if (e) {
    return {
      startDate: dateRange.start,
      endDate: end!,
    };
  }

  return {
    startDate: dateRange.start,
    endDate: dateRange.end,
  };
}

function convertToNumInCalendar(
  start: Date,
  end: Date,
  dateRange: DateRange,
): {start: number; end: number} {
  return {
    start: diffDays(start, dateRange.start) - 1,
    end: diffDays(end, dateRange.start),
  };
}

type TextAndStyle = {
  text: string;
  style: CSSProperties;
};

const modKeys = '_calendar_mod_keys';
const modStyles = '_calendar_mod_styles';
const modTexts = '_calendar_mod_texts';

function selectTextAndStyle(event: ResourceDetails, day: string): TextAndStyle {
  const keys = array(event[modKeys]);
  const texts = event[modTexts] || {};
  const styles = event[modStyles] || {};

  for (let key of keys) {
    const dates = toDates(event[key]);

    for (let date of dates) {
      const dateStr = getDateText(date);

      if (dateStr && dateStr === day) {
        return {
          text: texts[key] || texts['_'] || '',
          style: styles[key] || styles['_'] || {},
        };
      }
    }
  }

  return {
    text: texts['_'] || '',
    style: styles['_'] || {},
  };
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

const Row = styled.div`
  display: flex;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  color: ${textColorInverse};
  background-color: ${bodyColorPrimary};
  height: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  outline: 1px solid ${borderColorLight};
  outline-offset: -1px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
`;

const SubBar = styled(Bar)`
  outline: none;
  display: inline-block;
  text-align: center;
  line-height: 1rem;
  font-size: 0.8rem;
  padding-top: 1px;
`;
