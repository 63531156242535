import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {formatDate, toDate} from './common/dateutil';
import {DateWidget} from './DateWidget';

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  style?: CSSProperties;
  readOnly?: boolean;
};

export function DateListWidget(props: Props): JSX.Element | null {
  if (props.readOnly) {
    return <DateListOutputWidget {...props} />;
  }

  // Add an empty value to add the input field for new value
  const valuesAndNew = [...props.value, ''];

  const dates = valuesAndNew.map((v, i) => {
    return (
      <DateWidget
        {...props}
        key={`date-${i}`}
        value={v}
        onChange={(v) => {
          const newValues = [...props.value];

          if (!v) {
            newValues.splice(i, 1);
          } else {
            newValues[i] = v;
          }

          props.onChange(newValues);
        }}
      />
    );
  });

  return <Container>{dates}</Container>;
}

type OutputProps = {
  value: string[];
};

export function DateListOutputWidget(props: OutputProps): JSX.Element | null {
  const dates = props.value
    .map((v) => toDate(v))
    .filter((d) => !!d)
    .map((d) => formatDate(d));

  return (
    <Container style={{whiteSpace: 'pre-wrap'}}>{dates.join('\n')}</Container>
  );
}

const Container = styled.div``;
