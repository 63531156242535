import {mergeStyles, Persona, PersonaSize} from '@fluentui/react';
import React, {useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {api} from '../../api';
import {NavContext} from '../../context';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {borderColorLight, textColor, textColorTheme} from '../../styles';
import {lastBreadcrumbItem} from '../BreadcrumbList';
import {getIconName, getTitle, Props, useMenuItems} from '../Nav';
import '../Nav.css';
import {MobileMenu} from './MobileMenu';

function extractPath(props: Props) {
  return props.location.pathname;
}

function renderIcon(iconName: string) {
  if (!iconName) {
    return null;
  }

  return <MaterialIcon iconName={iconName} className={iconClass} />;
}

function NavComponent(props: Props) {
  const menuItems = useMenuItems(props);
  const path = extractPath(props);

  const item = lastBreadcrumbItem(props.nav.breadcrumb);
  const title = item.title || getTitle(path, menuItems);
  const iconName = item.iconName || getIconName(path, menuItems);

  return (
    <Container>
      <MobileMenu path={path} menuItems={menuItems} />
      <TitleContainer>
        {renderIcon(iconName)}
        {title}
      </TitleContainer>
      <PersonaContainer>
        <Persona
          text={api.getCurrentUser().name}
          size={PersonaSize.size24}
          hidePersonaDetails
        />
      </PersonaContainer>
    </Container>
  );
}

export function MobileNav() {
  const history = useHistory();
  const location = useLocation();
  const {nav} = useContext(NavContext);
  return <NavComponent history={history} location={location} nav={nav} />;
}

const Container = styled.div`
  border-bottom: 1px solid ${borderColorLight};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
  color: ${textColor};
`;

const PersonaContainer = styled.div`
  margin-right: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
`;

const iconClass = mergeStyles({
  fontSize: 16,
  height: 16,
  width: 16,
  userSelect: 'none',
  marginRight: 10,
  color: textColorTheme,
});
