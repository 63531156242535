import {Modal, Shimmer, ShimmerElementType} from '@fluentui/react';
import React, {CSSProperties, useState} from 'react';
import {Img} from 'react-image';
import styled, {css} from 'styled-components';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColor, borderColorLightest, textColorLightest} from '../styles';
import {array} from '../util';

type Props = {
  value: any;
  alt: string;
  size?: number;
  styles?: Styles;
};

type Styles = {
  root?: CSSProperties;
  img?: CSSProperties;
  notFound?: CSSProperties;
};

export function ImageOutputWidget(props: Props) {
  const list = array(props.value);
  //TODO edit mode?
  return (
    <Container>
      {list.map((src, i) => (
        <ImageComponent
          key={`img-${i}`}
          src={src}
          size={props.size || 100}
          alt={props.alt}
          styles={props.styles}
        />
      ))}
    </Container>
  );
}

type ImageProps = {
  src: string;
  alt: string;
  size: number;
  styles?: Styles;
};

function ImageComponent({src, alt, size, styles}: ImageProps) {
  const ss = styles || {};

  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <StyledImg
        style={ss.img}
        size={size}
        src={src}
        alt={alt}
        container={(children) => (
          <ImageContainer
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShow(true);
            }}
            style={ss.root}
            href={src}
            target="_blank"
            rel="noopener noreferrer">
            {children}
          </ImageContainer>
        )}
        loader={
          <Shimmer
            width={size + 'px'}
            shimmerElements={[
              {
                type: ShimmerElementType.line,
                height: size,
                width: size,
              },
            ]}
          />
        }
        unloaderContainer={(children) => (
          <NotFoundContainer style={ss.root}>{children}</NotFoundContainer>
        )}
        unloader={
          <NotFoundIconContainer size={size} style={ss.notFound}>
            <NotFoundIcon iconName={'help_outline'} />
          </NotFoundIconContainer>
        }
      />
      <Modal isOpen={show} isBlocking={false} onDismiss={() => setShow(false)}>
        <ActualImageContainer>
          <ActualImage src={src} alt={alt}></ActualImage>
        </ActualImageContainer>
      </Modal>
    </>
  );
}

type Content = {
  size: number;
  style?: CSSProperties;
};

function apply({size, style}: Content) {
  return {
    style: {
      ...{
        width: size + 'px',
        height: size + 'px',
        lineHeight: size + 'px',
      },
      ...style,
    },
  };
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const centering = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.a`
  ${centering};
  display: block;
  margin: 0.5rem;
  text-decoration: none;
`;

const NotFoundContainer = styled.div`
  ${centering};
  margin: 0.5rem;
`;

const NotFoundIconContainer = styled.div.attrs<Content>(apply)<Content>`
  ${centering};
  box-sizing: border-box;
  border: 5px solid ${borderColorLightest};
  width: 100px;
  height: 100px;
  background-color: ${bodyColor};
  user-select: none;
`;

const NotFoundIcon = styled(MaterialIcon)`
  font-size: 30px;
  color: ${textColorLightest};
`;

const StyledImg = styled(Img).attrs<Content>(apply)<Content>`
  box-sizing: border-box;
  border: 5px solid ${borderColorLightest};
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const ActualImageContainer = styled.div`
  max-width: 80vw;
  height: min(80vh, 600px);
`;

const ActualImage = styled.img`
  max-width: 80vw;
  height: min(80vh, 600px);
  object-fit: cover;
`;
