import React, {KeyboardEventHandler} from 'react';
import {FieldValueProps} from '../field/FieldValue';
import {WidgetStyles} from '../types/WidgetStyles';
import {AutoComplete, widgetProps} from '../widgets/common';
import {TextInputWidget, TextOutputWidget} from '../widgets/TextWidget';
import {useOnChange} from './hooks/useOnChange';
import {useTextProps} from './hooks/useTextProps';

export type Props = FieldValueProps & {
  multiline: boolean;
  autoComplete?: AutoComplete;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function TextField(props: Props): JSX.Element | null {
  const onChange = useOnChange(props.onChange, props.field.id);
  const textProps = useTextProps(props.field, props.multiline);
  const styles = widgetProps<WidgetStyles | undefined>(
    props.field,
    'styles',
    undefined,
  );

  if (props.readOnly) {
    return (
      <TextOutputWidget
        {...textProps}
        value={props.value}
        styles={props.styles || styles}
      />
    );
  }

  return <TextInputWidget {...props} {...textProps} onChange={onChange} />;
}
