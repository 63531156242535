import React from 'react';
import {BoolField} from '../fields/BoolField';
import {CheckboxField} from '../fields/CheckboxField';
import {ColorField} from '../fields/ColorField';
import {ComboBoxField} from '../fields/ComboBoxField';
import {DateField} from '../fields/DateField';
import {DateListField} from '../fields/DateListField';
import {ImageField} from '../fields/ImageField';
import {MarkdownField} from '../fields/MarkdownField';
import {MonthField} from '../fields/MonthField';
import {NumberField} from '../fields/NumberField';
import {RadioField} from '../fields/RadioField';
import {RichTextField} from '../fields/RichTextField';
import {TextField} from '../fields/TextField';
import {TextListField} from '../fields/TextListField';
import {TimeField} from '../fields/TimeField';
import {LinkWidget} from '../widgets/LinkWidget';
import {FieldValueProps} from './FieldValue';

export const renderTextField = (props: FieldValueProps) => {
  return <TextField {...props} multiline={false} />;
};

export const renderMultilineTextField = (props: FieldValueProps) => {
  return <TextField {...props} multiline={true} />;
};

export const renderTextListField = (props: FieldValueProps) => {
  return <TextListField {...props} multiline={false} />;
};

export const renderNumberField = (props: FieldValueProps) => {
  return <NumberField {...props} />;
};

export const renderCheckboxField = (props: FieldValueProps) => {
  return <CheckboxField {...props} />;
};

export const renderRadioField = (props: FieldValueProps) => {
  return <RadioField {...props} />;
};

export const renderLinkField = (props: FieldValueProps) => {
  return <LinkWidget {...props} />;
};

export const renderDateField = (props: FieldValueProps) => {
  return <DateField {...props} />;
};

export const renderDateListField = (props: FieldValueProps) => {
  return <DateListField {...props} />;
};

export const renderImageField = (props: FieldValueProps) => {
  return <ImageField {...props} />;
};

export const renderColorField = (props: FieldValueProps) => {
  return <ColorField {...props} />;
};

export const renderTimeField = (props: FieldValueProps) => {
  return <TimeField {...props} />;
};

export const renderMonthField = (props: FieldValueProps) => {
  return <MonthField {...props} />;
};

export const renderRichTextField = (props: FieldValueProps) => {
  return <RichTextField {...props} />;
};

export const renderBoolField = (props: FieldValueProps) => {
  return <BoolField {...props} />;
};

export const renderMarkdownField = (props: FieldValueProps) => {
  return <MarkdownField {...props} />;
};

export const renderComboBoxField = (props: FieldValueProps) => {
  return <ComboBoxField {...props} />;
};

export const renderNull = (_: unknown) => {
  return null;
};
