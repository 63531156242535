import React from 'react';
import {BarcodeField} from '../fields/BarcodeField';
import {EmbedInputField} from '../fields/EmbedField';
import {ItemCheckboxField} from '../fields/ItemCheckboxField';
import {ItemField} from '../fields/ItemField';
import {ProxyItemField} from '../fields/ProxyItemField';
import {QRCodeField} from '../fields/QRCodeField';
import {TableField} from '../fields/TableField';
import {Actions} from '../types/Action';
import {Errors} from '../types/Errors';
import {
  AttachmentFiles,
  RelatedProxies,
  RelatedResources,
} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {PasswordWidget} from '../widgets/PasswordWidget';
import {SelectWidget} from '../widgets/SelectWidget';
import {
  renderBoolField,
  renderCheckboxField,
  renderColorField,
  renderComboBoxField,
  renderDateField,
  renderDateListField,
  renderImageField,
  renderMarkdownField,
  renderMonthField,
  renderMultilineTextField,
  renderNull,
  renderNumberField,
  renderRadioField,
  renderRichTextField,
  renderTextField,
  renderTextListField,
  renderTimeField,
} from './CommonField';
import {renderFileField} from './DetailsFieldValue';
import {FieldValueProps} from './FieldValue';

export type FormFieldValueProps = FieldValueProps & {
  item: ResourceDetails;
  actions: Actions;
  relatedResources: RelatedResources;
  relatedProxies: RelatedProxies;
  attachmentFiles: AttachmentFiles;
  errors: Errors;
};

export function FormFieldValue(props: FormFieldValueProps) {
  const render =
    widgetFunctions[props.field.widget] ||
    typeFunctions[props.field.type] ||
    renderTextField;

  return render(props);
}

const renderPasswordField = (props: FormFieldValueProps) => {
  return <PasswordWidget {...props} />;
};

const renderSelectField = (props: FormFieldValueProps) => {
  return (
    <SelectWidget {...props} multiple={false} width={300} horizontal={true} />
  );
};

const renderItemField = (props: FormFieldValueProps) => {
  return <ItemField {...props} capacity={1} />;
};

export const renderItemListField = (props: FormFieldValueProps) => {
  return <ItemField {...props} capacity={0} />;
};

const renderProxyItemField = (props: FormFieldValueProps) => {
  const capacity = props.field.type === 'resid_list' ? 0 : 1;
  return <ProxyItemField {...props} capacity={capacity} />;
};

export const renderTableField = (props: FormFieldValueProps) => {
  return <TableField {...props} />;
};

const renderItemCheckboxField = (props: FormFieldValueProps) => {
  return <ItemCheckboxField {...props} />;
};

const renderQRCodeField = (props: FormFieldValueProps) => {
  return <QRCodeField {...props} />;
};

const renderBarcodeField = (props: FormFieldValueProps) => {
  return <BarcodeField {...props} />;
};

const renderEmbedField = (props: FormFieldValueProps) => {
  return <EmbedInputField {...props} />;
};

type RenderFormFieldFn = (props: FormFieldValueProps) => JSX.Element | null;

const widgetFunctions: {[key: string]: RenderFormFieldFn} = {
  string: renderTextField,
  text: renderMultilineTextField,
  checkbox: renderCheckboxField,
  select: renderSelectField,
  radio: renderRadioField,
  password: renderPasswordField,
  table: renderTableField,
  link: renderTextField,
  image: renderImageField,
  proxy_item: renderProxyItemField,
  time: renderTimeField,
  color: renderColorField,
  rich_text: renderRichTextField,
  month: renderMonthField,
  item_checkbox: renderItemCheckboxField,
  qrcode: renderQRCodeField,
  barcode: renderBarcodeField,
  scanner: renderNull,
  hidden: renderNull,
  markdown: renderMarkdownField,
  combobox: renderComboBoxField,
};

const typeFunctions: {[key: string]: RenderFormFieldFn} = {
  string: renderTextField,
  string_list: renderTextListField,
  int: renderNumberField,
  float: renderNumberField,
  date: renderDateField,
  date_list: renderDateListField,
  resid: renderItemField,
  resid_list: renderItemListField,
  file: renderFileField,
  text: renderMultilineTextField,
  bool: renderBoolField,
  embed: renderEmbedField,
};
