import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../../api';
import {selectComponent} from '../../components/Components';
import {ItemDetails, ItemDetailsProps} from '../../components/ItemDetails';
import {useMessage} from '../../hooks/useMessage';
import {borderColorLightest} from '../../styles';
import {Actions} from '../../types/Action';
import {Params} from '../../types/Params';
import {Resource} from '../../types/Resource';
import {Dialog} from '../Dialog';
import {extractSections} from '../Schema';
import {Spinner} from '../Spinner';
import {Toolbar} from '../Toolbar';

type Props = {
  ctx: ApiContext;
  shown: boolean;
  schemaId: string;
  resId?: string;
  params?: Params;
  title?: string;
  onClose: () => void;
  actions: Actions;
  minWidth?: string;
};

export function ItemDetailsDialog(props: Props): JSX.Element | null {
  const ref = useRef<Dialog>(null);
  const [res, setRes] = useState<Resource | null>(null);
  const defaultTitle = useMessage('Dialog.Details', 'Details');
  const title = props.title || defaultTitle;

  useEffect(() => {
    if (ref && ref.current && props.shown) {
      ref.current.showDialog();
    }
  }, [ref, props.shown]);

  const onOpen = async () => {
    try {
      if (props.resId) {
        const res = await api.show(
          props.ctx,
          props.schemaId,
          props.resId,
          props.params,
        );

        setRes(res);
      }
    } catch (e) {}
  };

  const onClose = () => {
    setRes(null);
    props.onClose();
  };

  const minWidth = props.minWidth || '600px';

  return (
    <Dialog
      ref={ref}
      modal={false}
      title={title}
      maxWidth="90vw"
      onOpen={onOpen}
      onClose={onClose}>
      <div style={{minWidth}}>
        <Details props={props} res={res} />
      </div>
    </Dialog>
  );
}

type DetailsProps = {
  res: Resource | null;
  props: Props;
};

function Details({res, props}: DetailsProps): JSX.Element | null {
  if (!res) {
    return <Spinner />;
  }

  const detailsProps = selectComponent<ItemDetailsProps>(
    res.schema.screen.components,
    'item',
  );

  if (!detailsProps) {
    return null;
  }

  const sections = extractSections(res.schema);

  return (
    <Container>
      <Toolbar
        buttons={[
          {
            type: 'show',
            onClick: () => {
              window.open(`/app/${props.schemaId}/${props.resId}`);
            },
          },
        ]}
        style={{borderTop: `1px solid ${borderColorLightest}`}}
      />
      <ItemDetailsContainer>
        <ItemDetails
          resource={res}
          actions={props.actions}
          labelPosition={detailsProps.labelPosition}
          sections={sections}
        />
      </ItemDetailsContainer>
    </Container>
  );
}

const Container = styled.div``;

const ItemDetailsContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow: auto;
`;
