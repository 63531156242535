import React, {useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {api} from '../api';
import {NavContext} from '../context';
import {ItemScreenComponent, ItemScreenURLMatch} from './ItemScreen';

export class NewItemScreenComponent extends ItemScreenComponent {
  componentDidMount() {
    this.loadNew();
  }

  loadNew = async () => {
    this.setState({
      status: 'loading',
    });

    const res = await api.new_(this.ctx, this.appId);

    this.setNav(res);
    this.setState({
      newResource: res,
      status: 'creating',
    });
  };
}

export function NewItemScreen(): JSX.Element | null {
  const params = useParams<ItemScreenURLMatch>();
  const {setNav} = useContext(NavContext);
  const location = useLocation();

  return (
    <NewItemScreenComponent {...params} location={location} setNav={setNav} />
  );
}
