import {
  ITextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import React, {RefObject} from 'react';
import styled from 'styled-components';
import {api} from '../api';
import {ApiError} from '../ApiError';
import {CurrentUser} from '../common/CurrentUser';
import {Logo} from '../common/Logo';
import {borderColorLightest, textColor} from '../styles';

type Props = {};

type State = {
  submitting: boolean;
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
  message: string;
  error: string;
};

export class Password extends React.Component<Props, State> {
  private readonly password: RefObject<ITextField>;
  private readonly passwordConfirmation: RefObject<ITextField>;
  private ctx: any;

  constructor(props: Props) {
    super(props);

    this.password = React.createRef();
    this.passwordConfirmation = React.createRef();

    this.state = {
      submitting: false,
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      message: '',
      error: '',
    };

    this.ctx = api.newContext();
  }

  componentWillUnmount(): void {
    this.ctx.abort();
  }

  changePassword = async () => {
    try {
      this.setState({
        submitting: true,
        message: '',
        error: '',
      });

      await api.changePassword(
        this.ctx,
        this.state.currentPassword,
        this.state.password,
        this.state.passwordConfirmation,
      );

      this.setState({
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
        message: 'パスワードを変更しました',
      });
    } catch (err) {
      if (err instanceof ApiError) {
        this.setState({error: err.getMessage()});
      } else {
        this.setState({error: 'パスワードを変更できませんでした'});
      }
    }
  };

  renderMessage() {
    if (!this.state.message) {
      return null;
    }

    return (
      <MessageBarContainer>
        <MessageBar
          messageBarType={MessageBarType.info}
          isMultiline={true}
          styles={{text: {fontSize: '0.9rem'}}}>
          {this.state.message}
        </MessageBar>
      </MessageBarContainer>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <MessageBarContainer>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          styles={{text: {fontSize: '0.9rem'}}}>
          {this.state.error}
        </MessageBar>
      </MessageBarContainer>
    );
  }

  renderForm() {
    return (
      <FormContainer>
        <form>
          <UserContainer>
            <CurrentUser />
            <HiddenField
              type={'text'}
              placeholder={'ユーザー名'}
              defaultValue={api.getCurrentUser().system_name}
              autoComplete={'username'}
              disabled
            />
          </UserContainer>
          <Field
            type={'password'}
            placeholder={'現在のパスワード'}
            onChange={(_, v) => {
              this.setState({currentPassword: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && this.password.current !== null) {
                this.password.current.focus();
              }
            }}
            value={this.state.currentPassword}
            autoComplete={'current-password'}
          />
          <Field
            type={'password'}
            placeholder={'新しいパスワード'}
            onChange={(_, v) => {
              this.setState({password: v || ''});
            }}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                this.passwordConfirmation.current !== null
              ) {
                this.passwordConfirmation.current.focus();
              }
            }}
            value={this.state.password}
            autoComplete={'new-password'}
          />
          <Field
            type={'password'}
            placeholder={'新しいパスワード(確認)'}
            componentRef={this.passwordConfirmation}
            onChange={(_, v) => {
              this.setState({passwordConfirmation: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.changePassword();
              }
            }}
            value={this.state.passwordConfirmation}
            autoComplete={'new-password'}
          />
          <PrimaryButton
            text={'パスワード変更'}
            onClick={this.changePassword}
            styles={{root: {marginTop: '1rem', width: '100%'}}}
          />
        </form>
      </FormContainer>
    );
  }

  render() {
    return (
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {this.renderMessage()}
        {this.renderError()}
        {this.renderForm()}
      </Container>
    );
  }
}

const fieldWidth = '300px';

const Container = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: white;
`;

const FormContainer = styled.div`
  & input {
    display: block;
  }
`;

const Field = styled(TextField)`
  margin-top: 1rem;
  width: ${fieldWidth};
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${borderColorLightest};
  height: 44px;
  border-radius: 24px;
`;

const HiddenField = styled(TextField)`
  margin-top: 1rem;
  width: ${fieldWidth};
  display: none;

  & input {
    color: ${textColor};
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 5rem;
`;

const MessageBarContainer = styled.div`
  font-size: 14px;
  width: 300px;
  text-align: left;
  margin-bottom: 1rem;
`;
