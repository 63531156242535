import {DefaultButton, TextField} from '@fluentui/react';
import React, {
  CSSProperties,
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {borderColorLightest} from '../styles';
import {WidgetStyles} from '../types/WidgetStyles';
import {AutoComplete, selectStringValue, textDecorator} from './common';

type OutputProps = {
  value: string;
  copy: boolean;
  styles?: WidgetStyles;
  prefix?: string;
  suffix?: string;
  digitSeparator?: boolean;
};

export function TextOutputWidget(props: OutputProps): JSX.Element | null {
  const intl = useIntl();
  const text = useRef<HTMLInputElement>(null);
  const copy = useCallback(() => {
    copyText(text);
  }, [text]);
  const style = (props.styles || {}).value;
  const value = selectStringValue(
    props.value,
    textDecorator(props.digitSeparator),
  );

  if (!value) {
    return null;
  }

  if (!props.copy) {
    return (
      <Container>
        <NullableText value={props.prefix} style={{marginRight: affixGap}} />
        <Text style={style}>{value}</Text>
        <NullableText value={props.suffix} style={{marginLeft: affixGap}} />
      </Container>
    );
  }

  return (
    <Container>
      <NullableText value={props.prefix} style={{marginRight: affixGap}} />
      <CopyText
        type={'text'}
        ref={text}
        style={style}
        defaultValue={value}
        readOnly={true}
      />
      <NullableText value={props.suffix} style={{marginLeft: affixGap}} />
      <DefaultButton
        text={intl.formatMessage({id: 'Action.Copy'})}
        onClick={copy}
        styles={{
          root: {
            marginLeft: '0.5rem',
            height: '2rem',
          },
        }}
      />
    </Container>
  );
}

const affixGap = '0.2rem';

type NullableTextProps = {
  value?: string;
  style?: CSSProperties;
};

function NullableText(props: NullableTextProps): JSX.Element | null {
  if (!props.value) {
    return null;
  }

  return <Text style={props.style}>{props.value}</Text>;
}

const copyText = (text: RefObject<HTMLInputElement>) => {
  navigator.clipboard
    .writeText(text.current!.value)
    .then(() => {
      // Select string as feedback after successful copy
      text.current!.select();
      text.current!.setSelectionRange(0, 99999); /*For mobile devices*/
    })
    .catch(() => {
      console.error('failed to copy');
    });
};

type InputProps = {
  value?: string;
  onChange: (v?: string) => void;
  maxLength?: number;
  prefix?: string;
  suffix?: string;
  pattern?: string;
  readOnly?: boolean;
  textProps?: {[key: string]: any};
  multiline: boolean;
  autoComplete?: AutoComplete;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const ignoreEnter: KeyboardEventHandler<
  HTMLInputElement | HTMLTextAreaElement
> = (e) => {
  if (e.key === 'Enter') {
    // enter
    e.preventDefault();
    e.stopPropagation();
  }
};

function doNothing() {}

export function TextInputWidget(props: InputProps): JSX.Element | null {
  const styles =
    props.maxLength && props.maxLength < 20
      ? {fieldGroup: {width: props.maxLength * 20}}
      : {};

  const fn = props.onChange;

  const [cachedValue, setCachedValue] = useState<string>(props.value || '');
  const [paused, setPaused] = useState<boolean>(false);

  useEffect(() => {
    const v = props.value || '';
    setCachedValue(v);
  }, [props.value]);

  const onChange = useCallback(
    (_: unknown, v?: string) => {
      if (!paused && fn) {
        fn(v);
      }

      setCachedValue(v || '');
    },
    [fn, paused],
  );

  const onKeyDown =
    props.onKeyDown || (props.multiline ? doNothing : ignoreEnter);

  return (
    <TextField
      disabled={props.readOnly}
      onChange={onChange}
      onCompositionStart={() => {
        setPaused(true);
      }}
      onCompositionEnd={() => {
        setPaused(false);

        if (fn) {
          fn(cachedValue);
        }
      }}
      value={cachedValue}
      autoComplete={props.autoComplete || 'off'}
      multiline={props.multiline}
      onKeyDown={onKeyDown}
      styles={styles}
      maxLength={props.maxLength}
      pattern={props.pattern}
      prefix={props.prefix}
      suffix={props.suffix}
      {...props.textProps}
    />
  );
}

export const Text = styled.div`
  display: inline-block;
  font-size: 1rem;
  white-space: pre-wrap;
`;

const Container = styled.div``;

const CopyText = styled.input`
  font-size: 1rem;
  height: 2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border: 1px solid ${borderColorLightest};
  border-radius: 2px;
  box-sizing: border-box;
`;
