import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../../api';
import {ApiError} from '../../ApiError';
import {bodyColorAccent} from '../../styles';
import {Errors} from '../../types/Errors';
import {Params} from '../../types/Params';
import {Resource} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {Dialog} from '../Dialog';
import {Spinner} from '../Spinner';

type Props = {
  ctx: ApiContext;
  shown: boolean;
  appId: string;
  resId: string;
  params?: Params;
  onAfter: (item: ResourceDetails) => void;
  onClose: (item: ResourceDetails) => void;
};

export const DeleteDialog = (props: Props) => {
  const ref = useRef<Dialog>(null);
  const [res, setRes] = useState<Resource | null>(null);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (props.shown && ref && ref.current) {
      ref.current.showDialog();
    }
  }, [props.shown, ref]);

  const onOpen = async () => {
    try {
      const res = await api.newDelete(
        props.ctx,
        props.appId,
        props.resId,
        props.params,
      );
      setRes(res);
    } catch (e) {}
  };

  const onSave = async () => {
    if (!res) {
      return false;
    }

    try {
      await api.remove(props.ctx, res.schema.id, res.id);
      props.onAfter(res.details);
      return true;
    } catch (e) {
      if (e instanceof ApiError) {
        setErrors(e.getMessageMap());
      }

      return false;
    }
  };

  return (
    <Dialog
      ref={ref}
      onExecute={onSave}
      onOpen={onOpen}
      onCancel={() => {
        setErrors({});

        if (res) {
          props.onClose(res.details);
        }
      }}
      onClose={() => {
        if (res) {
          props.onClose(res.details);
        }
      }}
      errors={errors['_']}
      title={'削除'}
      renderBody={() => {
        if (!res) {
          return <Spinner />;
        }

        return renderBody(res.details);
      }}
      executeLabel={'削除する'}
      maxWidth={'90vw'}
    />
  );
};

function renderBody(item?: ResourceDetails): JSX.Element | null {
  if (!item) {
    return null;
  }

  return (
    <MessageContainer>
      <p>
        <ItemName>{item.__name__ || item.name || 'リソース'}</ItemName>
        を削除しようとしています。
      </p>
      <p>本当に削除しますか？</p>
    </MessageContainer>
  );
}

const MessageContainer = styled.div`
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1.5;
  font-size: 1rem;
  max-width: 600px;
`;

const ItemName = styled.span`
  background-color: ${bodyColorAccent};
  padding: 0.3rem;
`;
