import {CommandBar, INavLink} from '@fluentui/react';
import {History, Location} from 'history';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Api, api, ApiContext, onClickWithPropsFn} from '../api';
import {MAIN_HEADER_HEIGHT, MENU_WIDTH} from '../consts';
import {NavContext, NavProps} from '../context';
import {materialIconClassName} from '../icons/materialIcons';
import {borderColorLight, textColor} from '../styles';
import {ToolButton} from '../types/ToolButton';
import {BreadcrumbList, BreadcrumbProps} from './BreadcrumbList';
import {buildItems} from './buttons/Buttons';
import {CurrentUser} from './CurrentUser';
import {Menu} from './Menu';
import './Nav.css';

export type Props = {
  location: Location;
  history: History;
  nav: NavProps;
};

function extractPath(props: Props) {
  return props.location.pathname + props.location.search;
}

function build(
  links: INavLink[],
  props: Props,
  api: Api,
  ctx: ApiContext,
): INavLink[] {
  return links
    .map((link) => {
      if (['markdown', 'search'].includes(link.type)) {
        return null;
      }

      if (link.links) {
        link.links = build(link.links, props, api, ctx);
      }

      if (link.url && !link.key) {
        link.key = link.url;
      }

      if (link.method) {
        link.onClick = (ev) => {
          if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
          }

          api.sendJson(ctx, link.method, link.url);
        };
      }

      if (link.onClickWithProps) {
        link.onClick = () => {
          (link.onClickWithProps as onClickWithPropsFn)(props);
        };
      }

      setIconClass(link);

      return link;
    })
    .filter((link) => link !== null) as INavLink[];
}

function setIconClass(link: INavLink) {
  if (!link.iconProps && link.icon) {
    link.iconProps = {
      iconName: link.icon,
      className: materialIconClassName(link.icon),
    };

    return;
  }

  if (link.iconProps && link.iconProps.iconName && !link.iconProps.className) {
    link.iconProps.className = materialIconClassName(link.iconProps.iconName);
  }
}

export function getIconName(path: string, menuItems: INavLink[]): string {
  if (menuItems.length === 0) {
    return '';
  }

  for (let item of menuItems) {
    if (item.url === path) {
      return item.icon || '';
    }

    if (item.links) {
      for (let child of item.links) {
        if (child.url === path) {
          return child.icon || '';
        }
      }
    }
  }

  return '';
}

export function getTitle(path: string, menuItems: INavLink[]): string {
  if (menuItems.length === 0) {
    return '';
  }

  for (let item of menuItems) {
    if (item.url === path) {
      return item.name;
    }

    if (item.links) {
      for (let child of item.links) {
        if (child.url === path) {
          return child.name;
        }
      }
    }
  }

  return '';
}

export function useMenuItems(props: Props): INavLink[] {
  const [menuItems, setMenuItems] = useState<INavLink[]>([]);

  useEffect(() => {
    const ctx = api.newContext();

    api
      .listMenu(ctx)
      .then((menuItems) => {
        setMenuItems(build(menuItems, props, api, ctx));
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      ctx.abort();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return menuItems;
}

function NavComponent(props: Props) {
  const menuItems = useMenuItems(props);
  const path = extractPath(props);

  const breadcrumb = selectBreadcrumb(props.nav.breadcrumb, {
    title: getTitle(path, menuItems),
    iconName: getIconName(path, menuItems),
  });

  return (
    <Container>
      <Menu path={path} menuItems={menuItems} />
      <TitleContainer>
        <BreadcrumbList list={breadcrumb} />
      </TitleContainer>
      <SubContainer>
        <Buttons buttons={props.nav.buttons} />
        <PersonaContainer>
          <CurrentUser />
        </PersonaContainer>
      </SubContainer>
    </Container>
  );
}

type ButtonsProps = {
  buttons: ToolButton[];
};

function Buttons(props: ButtonsProps): JSX.Element | null {
  if (props.buttons.length === 0) {
    return null;
  }

  const items = buildItems(props.buttons);
  return (
    <CommandBar
      items={items}
      styles={{root: {height: MAIN_HEADER_HEIGHT - 1 + 'px'}}}
    />
  );
}

function selectBreadcrumb(
  breadcrumb: BreadcrumbProps[],
  def: BreadcrumbProps,
): BreadcrumbProps[] {
  if (!breadcrumb || breadcrumb.length === 0) {
    return [def];
  }

  if (breadcrumb.length === 1 && breadcrumb[0].title === '') {
    return [def];
  }

  return breadcrumb;
}

export function Nav(): JSX.Element | null {
  const history = useHistory();
  const location = useLocation();
  const {nav} = useContext(NavContext);
  return <NavComponent history={history} location={location} nav={nav} />;
}

const Container = styled.div`
  border-bottom: 1px solid ${borderColorLight};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  width: ${MENU_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  color: ${textColor};
`;

const PersonaContainer = styled.div`
  margin-right: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
`;
