import {ChoiceGroup} from '@fluentui/react';
import * as React from 'react';
import styled from 'styled-components';
import {textColor} from '../styles';
import {Option} from '../types/Field';
import {ClearButton} from './common/ClearButton';

type Props = {
  value: string | number;
  options?: Option[];
  onChange?: (value: string | number) => void;
  vertical?: boolean;
  readOnly?: boolean;
  noClearButton?: boolean;
};

type OptionsProps = {
  value: string | number;
  options: Option[];
  onChange?: (value: string | number) => void;
  readOnly?: boolean;
};

function Options(props: OptionsProps): JSX.Element | null {
  const onChange = (v: string) => {
    if (props.onChange) {
      props.onChange(v);
    }
  };

  const opts = props.options.map((option) => ({
    key: option.value,
    text: option.label,
  }));

  return (
    <ChoiceGroup
      selectedKey={props.value ?? ''}
      options={opts}
      onChange={(_, option) => {
        const value = (option || {}).key;
        onChange(value === undefined ? '' : value);
      }}
      disabled={props.readOnly}
    />
  );
}

export class RadioWidget extends React.Component<Props> {
  onChange = (value: string) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  onClear = () => {
    this.onChange('');
  };

  renderOptions() {
    if (this.props.vertical) {
      return (
        <VerticalWrapper>
          {this.renderChoiceGroup()}
          {this.renderClearButton()}
        </VerticalWrapper>
      );
    }

    return (
      <HorizontalWrapper>
        {this.renderChoiceGroup()}
        {this.renderClearButton()}
      </HorizontalWrapper>
    );
  }

  renderChoiceGroup() {
    return (
      <Options
        value={this.props.value}
        options={this.props.options || []}
        onChange={this.props.onChange}
        readOnly={this.props.readOnly}
      />
    );
  }

  renderClearButton() {
    if (this.props.readOnly) {
      return null;
    }

    if (this.props.noClearButton) {
      return null;
    }

    return (
      <ClearButton
        onClick={this.onClear}
        styles={{root: {marginTop: '0.5rem'}}}
      />
    );
  }

  render() {
    if (!this.props.options) {
      return null;
    }

    return <Wrapper>{this.renderOptions()}</Wrapper>;
  }
}

const Wrapper = styled.div``;

const iconSize = 20;

const VerticalWrapper = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-right: 1.5rem;

  & .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    line-height: ${iconSize}px;
  }

  & input:disabled:checked + .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    color: ${textColor};
  }

  & input:disabled:checked + .ms-ChoiceField-field::before {
    &::before,
    &::after {
      border-color: ${textColor};
    }
  }
`;

const HorizontalWrapper = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  & .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    line-height: ${iconSize}px;
  }

  & input:disabled:checked + .ms-ChoiceField-field .ms-ChoiceFieldLabel {
    color: ${textColor};
  }

  & input:disabled:checked + .ms-ChoiceField-field {
    &::before,
    &::after {
      border-color: ${textColor};
    }
  }

  & .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }

  & .ms-ChoiceField {
    margin-right: 1.5rem;
    margin-top: 0;
  }
`;
