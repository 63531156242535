import {DefaultButton, IconButton} from '@fluentui/react';
import * as React from 'react';
import {ChangeEvent, RefObject, useRef, useState} from 'react';
import styled from 'styled-components';
import {bodyColorHovered, borderColorLightest} from '../styles';
import {AttachmentFile} from '../types/AttachmentFile';

type OutputProps = {
  files: AttachmentFile[];
};

export function FileOutputWidget(props: OutputProps): JSX.Element | null {
  return (
    <div>
      {props.files.map((file, i) => {
        if (/^image/.test(file.contentType)) {
          //FIXME do not specify width
          return (
            <ImageContainer key={`file-${i}`}>
              <a target="_blank" rel="noopener noreferrer" href={file.url}>
                <Image key={`file-${file.id}`} src={file.url} size={200} />
              </a>
            </ImageContainer>
          );
        }

        return (
          <FileName key={`file-${i}`}>
            <a target="_blank" rel="noopener noreferrer" href={file.url}>
              {file.name}
            </a>
          </FileName>
        );
      })}
    </div>
  );
}

type InputProps = {
  files: File[];
  onChangeFiles: (files: File[]) => void;
  onChangeExistingFiles: (files: AttachmentFile[]) => void;
  existingFiles: AttachmentFile[];
  readOnly: boolean;
  multiple: boolean;
  fileTypes?: string[];
};

export function FileInputWidget(props: InputProps): JSX.Element | null {
  const input = useRef<HTMLInputElement>(null);
  const [existingFiles, setExistingFiles] = useState(props.existingFiles);
  const [files, setFiles] = useState<File[]>(props.files);

  if (props.readOnly) {
    return (
      <Container>
        <SelectedFiles
          existingFiles={existingFiles}
          files={files}
          readOnly={props.readOnly}
        />
      </Container>
    );
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = convertToFiles(e.target.files);
    const allFiles = props.multiple ? files.concat(newFiles) : newFiles;
    setFiles(allFiles);
    props.onChangeFiles(allFiles);

    if (!props.multiple) {
      setExistingFiles([]);
      props.onChangeExistingFiles([]);
    }

    e.target.value = ''; // clear real input value
  };

  const onRemove = (file: File | AttachmentFile) => {
    if (file instanceof File) {
      const rest = files.filter((f) => f !== file);
      setFiles(rest);
      props.onChangeFiles(rest);
      return;
    }

    const rest = existingFiles.filter((f) => f !== file);
    setExistingFiles(rest);
    props.onChangeExistingFiles(rest);
  };

  const inputProps = buildInputProps(props);

  return (
    <Container>
      <SelectButton input={input} />
      <SelectedFiles
        existingFiles={existingFiles}
        files={files}
        onRemove={onRemove}
      />
      <input
        type={'file'}
        multiple={props.multiple}
        onChange={onChange}
        ref={input}
        {...inputProps}
      />
    </Container>
  );
}

function buildInputProps(props: InputProps): {[key: string]: any} {
  if (props.fileTypes && props.fileTypes.length > 0) {
    return {
      accept: props.fileTypes.join(','),
    };
  }

  return {};
}

const convertToFiles = (fileList: FileList | null): File[] => {
  if (!fileList) {
    return [];
  }

  const files: File[] = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    if (file) {
      files.push(file);
    }
  }

  return files;
};

type SelectButtonProps = {
  input: RefObject<HTMLInputElement>;
};

function SelectButton(props: SelectButtonProps): JSX.Element | null {
  return (
    <DefaultButton
      text={'ファイル選択'}
      onClick={() => {
        if (props.input.current) {
          props.input.current.click();
        }
      }}
    />
  );
}

type SelectedFilesProps = {
  existingFiles: AttachmentFile[];
  files: File[];
  onRemove?: (f: File | AttachmentFile) => void;
  readOnly?: boolean;
};

function SelectedFiles(props: SelectedFilesProps): JSX.Element | null {
  if (!props.existingFiles && !props.files) {
    return null;
  }

  const files: JSX.Element[] = [];

  if (props.existingFiles) {
    props.existingFiles.forEach((file, i) => {
      files.push(
        <SelectedFile
          key={`existing-file-${i}`}
          name={file.name}
          readOnly={props.readOnly}
          onRemove={() => {
            if (props.onRemove) {
              props.onRemove(file);
            }
          }}
        />,
      );
    });
  }

  if (props.files) {
    props.files.forEach((file, i) => {
      files.push(
        <SelectedFile
          key={`file-${i}`}
          name={file.name}
          readOnly={props.readOnly}
          onRemove={() => {
            if (props.onRemove) {
              props.onRemove(file);
            }
          }}
        />,
      );
    });
  }

  return <Files>{files}</Files>;
}

type SelectedFileProps = {
  name: string;
  onRemove: () => void;
  readOnly?: boolean;
};

function SelectedFile(props: SelectedFileProps): JSX.Element | null {
  if (props.readOnly) {
    return (
      <FileItem>
        <FileName title={props.name}>{props.name}</FileName>
      </FileItem>
    );
  }

  return (
    <FileItem>
      <FileName title={props.name}>{props.name}</FileName>
      <IconButton
        iconProps={{iconName: 'cancel'}}
        onClick={() => {
          props.onRemove();
        }}
      />
    </FileItem>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & input[type='file'] {
    display: none;
  }
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FileName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.5;
`;

const FileItem = styled.div`
  display: flex;
  max-width: 300px;
  width: fit-content;
  align-items: center;
  padding-left: 0.5em;
  font-size: 14px;

  &:hover {
    background-color: ${bodyColorHovered};
  }
`;

const ImageContainer = styled.div`
  border: 5px solid ${borderColorLightest};
  width: fit-content;
  object-fit: cover;

  & + & {
    margin-top: 10px;
  }
`;

const Image = styled.img<{size: number}>`
  max-height: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
`;
