import React, {useEffect, useState} from 'react';
import {api, useApiContext} from '../api';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {RelatedResources, RelatedSchemas} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {array} from '../util';
import {ItemWidget} from '../widgets/ItemWidget';
import {useItemProps} from './hooks/useItemProps';
import {useOnChange} from './hooks/useOnChange';
import {useOnClear} from './hooks/useOnClear';

type Props = {
  value: any;
  onChange: OnChangeFieldValue;
  field: Field;
  item?: ResourceDetails;
  actions: Actions;
  relatedResources?: RelatedResources;
  relatedSchemas?: RelatedSchemas;
  capacity?: number;
};

export function ItemField(props: Props): JSX.Element | null {
  const ids = array(props.value);
  const itemProps = useItemProps(
    props.field,
    props.item,
    props.relatedSchemas,
    props.capacity,
  );
  const items = useItems(props.field.itemType, props.relatedResources);
  const onChange = useOnChange(props.onChange, props.field.id);
  const onClear = useOnClear(props.onChange, props.field.id);

  if (!itemProps || !items) {
    return null;
  }

  return (
    <ItemWidget
      {...props}
      {...itemProps}
      {...items}
      value={ids}
      onChange={onChange}
      onClear={onClear}
    />
  );
}

type Items = {
  useIdsToItems: (ids: string[]) => ResourceDetails[];
  useItemsToIds: (items: ResourceDetails[]) => string[];
};

function useItems(
  schemaId?: string,
  relatedResources?: RelatedResources,
): Items | null {
  const [relates, setRelates] = useState<RelatedResources>({
    ...relatedResources,
  });
  const ctx = useApiContext();

  if (!schemaId) {
    return null;
  }

  const useItemsToIds = (v: ResourceDetails[]): string[] => {
    const ids = array(v).map((x) => x.id);
    const newRelates = {...relates};

    for (let x of array(v)) {
      newRelates[x.id] = x;
    }

    setRelates(newRelates);
    return ids;
  };

  const useIdsToItems = (ids: string[]): ResourceDetails[] => {
    useEffect(() => {
      (async () => {
        const newRelates = {...relates};
        let changed = false;

        for (let id of ids) {
          if (newRelates[id]) {
            continue;
          }

          try {
            const v = await api.show(ctx, schemaId, id);
            newRelates[id] = v.details;
            changed = true;
          } catch (ignore) {}
        }

        if (changed) {
          setRelates(newRelates);
        }
      })();
    }, [ids]);

    return items(ids, relates);
  };

  return {useItemsToIds, useIdsToItems};
}

function items(ids: string[], relates: RelatedResources) {
  return [...new Set(relatedItems(ids, relates))];
}

export function relatedItems(
  ids: string[],
  relates?: RelatedResources,
): ResourceDetails[] {
  if (!relates) {
    return [];
  }

  return ids.map((id) => relates[id]).filter((item) => !!item);
}
