import * as React from 'react';
import {useCallback} from 'react';
import styled from 'styled-components';
import {OnChangeFieldValue} from '../field/FieldValue';
import {Field} from '../types/Field';
import {array} from '../util';
import {CheckboxWidget, OnChangeFn} from '../widgets/CheckboxWidget';
import {widgetProps} from '../widgets/common';

type Props = {
  value: any;
  field: Field;
  onChange?: (v: any) => void;
  readOnly?: boolean;
};

export function CheckboxField(props: Props) {
  const capacity = widgetProps<number>(props.field, 'capacity', 0);
  const vertical = widgetProps<boolean>(props.field, 'vertical', false);

  const fn = useCallback<OnChangeFn>(
    (v, checked) => {
      onChangeValue(
        props.field.id,
        capacity,
        props.value,
        v,
        checked,
        props.onChange,
      );
    },
    [props.field.id, capacity, props.value, props.onChange],
  );

  if (!props.field.options) {
    return null;
  }

  return (
    <Container>
      <CheckboxWidget {...props} options={props.field.options} onChange={fn} vertical={vertical} />
    </Container>
  );
}

export function onChangeValue(
  fieldId: string,
  capacity: number,
  value: any,
  newValue: string,
  checked?: boolean,
  onChange?: OnChangeFieldValue,
) {
  // copy to prevent value object from being updated
  let newValues = [...array(value)];
  const index = newValues.indexOf(newValue);

  if (checked && index < 0) {
    newValues.push(newValue);
  } else if (index > -1) {
    newValues.splice(index, 1);
  }

  if (capacity > 0 && newValues.length > capacity) {
    newValues = newValues.slice(newValues.length - capacity);
  }

  if (onChange) {
    onChange({[fieldId]: newValues});
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
