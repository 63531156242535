import React, {useEffect, useState} from 'react';
import {api, ApiContext} from '../../api';
import {PARAM_KEY_SELECTOR, PARAM_KEY_SIZE} from '../../consts';
import {Params} from '../../types/Params';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList} from '../../types/ResourceList';
import {CandidateColumn, ColumnContainer, FilterColumn} from './elements';
import {ItemCandidates} from './ItemCandidates';
import {ItemFilter} from './ItemFilter';
import {selectSchemaIdAndParams, Steps} from './Step';

type Props = {
  ctx: ApiContext;
  selected: ResourceDetails[];
  onSelect: (items: ResourceDetails[]) => void;
  onSelectAll: (items: ResourceDetails[]) => void;
  itemType: string;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  disabledIds: string[];
  steps: Steps;
  defaultParams: Params; // widgetProps(params)
  tree: boolean;
  expand: boolean;
  candidateTitle: string;
  nameFieldId: string;
};

function useSelectorState() {
  const [candidates, setCandidates] = useState<ResourceList>();
  const [filter, setFilter] = useState<ResourceList>();
  const [filtered, setFiltered] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [stepRoutes, setStepRoutes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return {
    candidates,
    setCandidates,
    filter,
    setFilter,
    filtered,
    setFiltered,
    stepIndex,
    setStepIndex,
    stepRoutes,
    setStepRoutes,
    loading,
    setLoading,
  };
}

type State = ReturnType<typeof useSelectorState>;

export function FilterAndCandidates(props: Props): JSX.Element {
  const state = useSelectorState();

  useEffect(() => {
    resetList(props, state, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FilterColumn>
        <ColumnContainer>
          <ItemFilter
            {...props}
            resourceList={state.filter}
            onUpdateList={state.setCandidates}
            actions={{}}
            onUpdateFiltered={state.setFiltered}
            onResetList={() => {
              resetList(props, state, state.stepIndex);
            }}
            defaultParams={{
              ...props.defaultParams,
              ...getStepParams(props, state),
            }}
            forceParams={commonSelectorParams}
          />
        </ColumnContainer>
      </FilterColumn>
      <CandidateColumn>
        <ColumnContainer>
          <ItemCandidates
            {...props}
            list={state.candidates}
            onSelect={props.onSelect}
            onSelectAll={() => {
              const items = merge(props.selected, state.candidates?.list);
              props.onSelectAll(items);
            }}
            onUpdateList={state.setCandidates}
            onUpdateFilter={state.setFilter}
            stepIndex={state.stepIndex}
            onUpdateStepIndex={state.setStepIndex}
            filtered={state.filtered}
            onUpdateFiltered={state.setFiltered}
            forceParams={commonSelectorParams}
            headerTitle={props.candidateTitle}
          />
        </ColumnContainer>
      </CandidateColumn>
    </>
  );
}

async function resetList(props: Props, state: State, stepIndex: number) {
  state.setLoading(true);

  const validStepIndex = stepIndex > 0 ? stepIndex : 0;

  const {schemaId, filterSchemaId, params} = selectSchemaIdAndParams(
    props.steps,
    validStepIndex,
    props.itemType,
    props.defaultParams,
  );

  const list = await api.list(props.ctx, schemaId, {
    ...getStepParams(props, state),
    ...params,
    ...commonSelectorParams,
  });

  const filter = await newFilter(props, list, filterSchemaId);

  state.setCandidates(list);
  state.setFilter(filter);
  state.setStepIndex(validStepIndex);
  state.setFiltered(false);
  state.setLoading(false);
}

async function newFilter(
  props: Props,
  list: ResourceList,
  filterSchemaId?: string,
): Promise<ResourceList> {
  if (filterSchemaId) {
    return await api.list(props.ctx, filterSchemaId, {});
  }

  return list;
}

function getStepParams(props: Props, state: State) {
  const step = props.steps[state.stepIndex - 1];

  if (!step) {
    return {};
  }

  return {
    [step.ref_field_id]: state.stepRoutes[state.stepRoutes.length - 1],
  };
}

function merge(selected: ResourceDetails[], candidates?: ResourceDetails[]) {
  const merged = [...selected];

  const selectedIds = new Set(selected.map((x) => x.id));

  for (let x of candidates || []) {
    if (!selectedIds.has(x.id)) {
      merged.push(x);
    }
  }

  return merged;
}

const commonSelectorParams = {
  [PARAM_KEY_SIZE]: '100',
  [PARAM_KEY_SELECTOR]: '1',
};
