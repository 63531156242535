import React, {CSSProperties, useMemo} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {bodyColorAlt, borderColorLight, textColorError} from '../../styles';
import {ButtonWidget} from '../ButtonWidget';
import {Action, ActionButtons, ActionDef} from './ActionButtons';
import {LogoButton} from './LogoButton';
import {MultipleItemSelector} from './MultipleItemSelector';
import {ScanData} from './ScanData';
import {SingleItemSelector} from './SingleItemSelector';

type Props = {
  data: ScanData;
  userLabel: string;
  thingLabel: string;
  size: number;
  useSchedule: boolean;
  actions: ActionDef[];
};

export function UsersAndThings(props: Props): JSX.Element {
  const intl = useIntl();
  const headerStyle = useHeaderStyle(props.size);
  const width = calcWidth(props.size);
  const {min, max} = calcButtonWidth(props.size);

  return (
    <>
      <DataContainer>
        <ItemsContainer width={width}>
          <Header style={headerStyle}>{props.userLabel}</Header>
          <Body size={props.size}>
            <SingleItemSelector
              checkedId={props.data.getUserId()}
              items={props.data.getUsers()}
              onChecked={props.data.setUserId}
              size={props.size}
            />
          </Body>
        </ItemsContainer>
        <ItemsContainer style={{flexGrow: 1}} width={width}>
          <Header style={headerStyle}>{props.thingLabel}</Header>
          <Body size={props.size}>
            <MultipleItemSelector
              checkedIds={props.data.getThingIds()}
              items={props.data.getThings()}
              onChecked={props.data.setThingIds}
              size={props.size}
            />
          </Body>
        </ItemsContainer>
        {props.data.getUnknown().length > 0 ? (
          <ItemsContainer width={width}>
            <Header style={headerStyle}>
              {intl.formatMessage({
                id: 'Widget.Scan.Unknown',
                defaultMessage: 'Unknown',
              })}
            </Header>
            <Body size={props.size} style={{width, overflow: 'hidden'}}>
              {props.data.getUnknown().map((id, i) => (
                <Unknown key={`unknown-${i}`}>{id}</Unknown>
              ))}
            </Body>
          </ItemsContainer>
        ) : null}
      </DataContainer>
      <Buttons>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.doCancel}
            label={intl.formatMessage({
              id: 'Widget.Scan.Action.Cancel',
              defaultMessage: 'Cancel',
            })}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            styles={{
              container: {justifyContent: 'start'},
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <ActionButtons
          {...props}
          minWidth={min}
          maxWidth={max}
          actions={buildActions(props.actions, props.data, props.useSchedule)}
        />
        <LogoButton />
      </Buttons>
    </>
  );
}

function buildActions(
  actions: ActionDef[],
  data: ScanData,
  useSchedule: boolean,
): Action[] {
  return actions.map((action) => ({
    label: action.label,
    execute: toFunction(action, data, useSchedule),
  }));
}

function toFunction(
  action: ActionDef,
  data: ScanData,
  useSchedule: boolean,
): () => void {
  switch (action.value) {
    case 'out':
      return useSchedule && !data.scheduleNotRequired()
        ? data.goNext
        : data.doOut;
    case 'in':
      return data.doIn;
    case 'temp':
      return data.doTemp;
    default:
      return () => {};
  }
}

export function useDefaultActions(): ActionDef[] {
  const intl = useIntl();

  return [
    {
      value: 'out',
      label: intl.formatMessage({
        id: 'Widget.Scan.Action.Out',
        defaultMessage: 'Take out',
      }),
    },
    {
      value: 'in',
      label: intl.formatMessage({
        id: 'Widget.Scan.Action.In',
        defaultMessage: 'Return',
      }),
    },
  ];
}

export function useHeaderStyle(size: number): CSSProperties {
  return useMemo(() => {
    return makeHeaderStyle(size);
  }, [size]);
}

function makeHeaderStyle(size: number): CSSProperties {
  return {
    fontSize: size + 'px',
  };
}

export function calcWidth(size: number): number {
  return size * 10;
}

export function calcButtonWidth(size: number): {min: number; max: number} {
  return {min: size * 6, max: size * 10};
}

export const DataContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  gap: 1rem;
  min-height: 80vh;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    min-height: auto;
  }
`;

export const ItemsContainer = styled.div<{width: number}>`
  border: 1px solid ${borderColorLight};
  min-width: ${({width}) => `${width}px`};
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    flex-grow: 1;
  }
`;

export const Header = styled.div`
  background: ${bodyColorAlt};
  padding: 0.5rem;
  text-align: center;
`;

export const Body = styled.div<{size: number}>`
  padding: 0.5rem;
  height: ${({size}) => `calc(100% - 1rem - ${size}px)`};
  font-size: ${({size}) => `${size}px`};
  box-sizing: border-box;
  overflow: auto;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ButtonContainer = styled.div`
  flex-grow: 1;
`;

const Unknown = styled.div`
  font-family: monospace;
  color: ${textColorError};
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
