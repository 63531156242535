import {Checkbox, Dropdown, IDropdownOption} from '@fluentui/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {CellProps, Column, HeaderProps} from 'react-table';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {ignoreEmpty} from '../components/Filter';
import {
  PARAM_KEY_ORDER,
  PARAM_KEY_PAGE,
  PARAM_KEY_SIZE,
  PARAM_KEY_SORT,
} from '../consts';
import {ButtonField} from '../fields/ButtonField';
import {CheckboxField} from '../fields/CheckboxField';
import {FlagField} from '../fields/FlagField';
import {textColor} from '../styles';
import {Actions} from '../types/Action';
import {Field, Option} from '../types/Field';
import {FormValues} from '../types/Form';
import {Params} from '../types/Params';
import {AttachmentFiles, RelatedResources} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Schema} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {SortOrder} from '../types/SortOrder';
import {TableTool} from '../types/Table';
import {TableColumn} from '../types/TableColumn';
import {TableFilter} from '../types/TableFilter';
import {array} from '../util';
import {widgetProps} from '../widgets/common';
import {DateListOutputWidget} from '../widgets/DateListWidget';
import {DateWidget} from '../widgets/DateWidget';
import {ImageOutputWidget} from '../widgets/ImageWidget';
import {makeStyleForDeleted} from '../widgets/item/Item';
import {MonthOutputWidget} from '../widgets/MonthWidget';
import {TimeOutputWidget} from '../widgets/TimeWidget';
import {minus} from './set/set';
import {headerDecorator, nullColumn, Table} from './table/Table';

const Container = styled.div``;

const Header = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px;
`;

const Cell = styled.div`
  padding: 8px;
  width: 100%;
`;

const Text = styled.div`
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const ImageCell = styled.div`
  padding: 2px;
  text-align: center;
`;

const Image = styled.img<{size: number}>`
  max-height: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
`;

type Value = any;
type OptionMap = {[value: string]: string};
type OptionMaps = {[fieldId: string]: OptionMap};
type FieldMap = {[fieldId: string]: Field};

export const DEFAULT_COLUMN_WIDTH = 150;

type columnsDecorator = (columns: StickyColumn[]) => StickyColumn[];

type Props = {
  resourceList: ResourceList;
  defaultWidth?: number;
  columns: TableColumn[];
  onSelect: (item: ResourceDetails) => void;
  selectedIds?: string[];
  expandLastColumn: boolean;
  onChangePageSize: (size: number) => void;
  onChangeCurrentPage: (index: number) => void;
  onChangeSorter: (id: string, current: SortOrder) => void;
  currentSortOrder?: SortOrder;
  sorters?: Sorter[];
  filters?: TableFilter[];
  onChangeFilter?: (values: FormValues) => void;
  emptyMessage?: string;
  tableTools?: TableTool[];
  tableFarTools?: TableTool[];
  actions: Actions;
  columnsDecorator?: columnsDecorator;
  headerDecorator?: headerDecorator;
} & CheckboxProps;

export type CheckboxProps = {
  checkbox?: boolean;
  onCheck?: (ids: Set<string>, checked: boolean) => void;
  checkedIds?: Set<string>;
};

export function ResourceDetailsTable(props: Props) {
  const {cols, setCols} = useVisibleColumns(props.columns);

  let columns = buildColumns(
    props,
    props.resourceList,
    cols,
    props.expandLastColumn,
    props.checkbox,
    props.checkedIds,
    props.onCheck,
    props.defaultWidth,
  );

  if (props.columnsDecorator) {
    columns = props.columnsDecorator(columns);
  }

  const rows = buildRows(props.resourceList);

  return (
    <Container>
      <Table<ResourceDetails>
        {...props}
        columns={columns}
        rows={rows}
        onSelectRow={props.onSelect}
        count={props.resourceList.count}
        currentPage={props.resourceList.currentPage}
        pageSize={props.resourceList.pageSize}
        maxPage={props.resourceList.maxPage}
        allColumns={props.columns}
        currentColumns={cols}
        fields={props.resourceList.schema.fields}
        onChangeColumns={setCols}
      />
    </Container>
  );
}

type VisibleColumns = {
  cols: TableColumn[];
  setCols: (columns: TableColumn[]) => void;
};

function useVisibleColumns(columns: TableColumn[]): VisibleColumns {
  // If the schema of the list is different from the schema of the filter, props.columns may switch dynamically.
  // If this happens, the cols have to be reconfigured.
  // However, if it is simply judged by the identity of props.columns,
  // the cols will be reset every time when a user do filter,
  // even if the schema of the list and the schema of the filter are the same.
  // To avoid this, save the value of props.columns and do not update it
  // when the contents are the same as the value of previous props.columns.

  const [cols, setCols] = useState<TableColumn[]>(selectVisible(columns));
  const [propsColumns, setPropsColumns] = useState<TableColumn[]>(columns);

  useEffect(() => {
    if (!columnsAreEqual(propsColumns, columns)) {
      setCols(selectVisible(columns));
      setPropsColumns(columns);
    }
  }, [columns, propsColumns]);

  return {cols, setCols};
}

function columnsAreEqual(cols1: TableColumn[], cols2: TableColumn[]): boolean {
  if (cols1.length !== cols2.length) {
    return false;
  }

  for (let i = 0; i < cols1.length; i++) {
    const c1 = cols1[i];
    const c2 = cols2[i];

    if (
      c1.fieldId !== c2.fieldId ||
      c1.label !== c2.label ||
      c1.visible !== c2.visible
    ) {
      return false;
    }
  }

  return true;
}

function selectVisible(columns: TableColumn[]): TableColumn[] {
  return columns.filter((c) => c.visible);
}

function sort(field: Field) {}

function getFilter(
  filters: TableFilter[] | undefined,
  id: string,
): TableFilter | null {
  if (!filters) {
    return null;
  }

  for (let f of filters) {
    if (f.id === id) {
      return f;
    }
  }

  return null;
}

function renderHeaderCell(
  field: Field,
  col: TableColumn,
  headerProps: HeaderProps<ResourceDetails>,
  props: Props,
) {
  return (
    <HeaderCell
      col={col}
      field={field}
      filters={props.filters}
      params={props.resourceList.params}
      onChangeFilter={props.onChangeFilter}
    />
  );
}

type HeaderCellProps = {
  col: TableColumn;
  field: Field;
  filters?: TableFilter[];
  params: Params;
  onChangeFilter?: (values: FormValues) => void;
};

function HeaderCell(props: HeaderCellProps): JSX.Element | null {
  const label = props.col.label || props.field.label;
  const filter = getFilter(props.filters, props.field.id);

  if (filter && filter.options && props.onChangeFilter) {
    return (
      <HeaderWithFilter
        label={label}
        filter={filter}
        onChangeFilter={props.onChangeFilter}
        values={array(props.params[filter.id])}
      />
    );
  }

  return (
    <Header
      onClick={() => {
        sort(props.field);
      }}
      title={label}>
      {label}
    </Header>
  );
}

type HeaderWithFilterProps = {
  label: string;
  filter: TableFilter;
  onChangeFilter: (values: FormValues) => void;
  values: string[];
};

function HeaderWithFilter(props: HeaderWithFilterProps): JSX.Element {
  const options = buildOptions(props.filter.options, props.values);

  return (
    <Dropdown
      options={options}
      multiSelect={true}
      placeholder={props.label}
      onRenderTitle={(selectedOptions) => {
        return <div>{props.label}</div>;
      }}
      onChange={(_, opt) => {
        if (opt) {
          const newValues = addOrRemove(
            props.values,
            String(opt.key),
            opt.selected,
          );
          props.onChangeFilter({location_id: newValues});
        }
      }}
      styles={{
        title: {
          border: 'none',
          color: textColor,
        },
      }}
    />
  );
}

function addOrRemove(
  values: string[],
  value: string,
  add: boolean | undefined,
): string[] {
  if (!value) {
    return values;
  }

  if (add) {
    return values.concat(value);
  }

  return values.filter((v) => {
    return v !== value;
  });
}

function buildOptions(options: Option[], values: string[]): IDropdownOption[] {
  return options.map((o) => {
    return {
      key: o.value,
      text: o.label,
      selected: values.includes(o.value),
    };
  });
}

function renderValue(
  field: Field,
  options: OptionMaps,
  relatedResources: RelatedResources,
  cellProps: CellProps<ResourceDetails>,
  props: Props,
  column: TableColumn,
) {
  if (field.widget === 'button') {
    return (
      <ButtonField
        schema={props.resourceList.schema}
        field={field}
        actions={props.actions}
        item={cellProps.row.original}
      />
    );
  }

  if (field.widget === 'flag') {
    return (
      <FlagField
        value={cellProps.cell.value}
        schema={props.resourceList.schema}
        field={field}
        actions={props.actions}
        item={cellProps.row.original}
      />
    );
  }

  const value = cellProps.cell.value;

  if (value === null || value === undefined) {
    return null;
  }

  const v = chooseVisibleValue(value, field, options, relatedResources);
  const style = {...column.styles?.body};

  if (!field) {
    return (
      <Text title={v} style={style}>
        {v}
      </Text>
    );
  }

  if (field.widget === 'time') {
    return <TimeOutputWidget value={v} />;
  }

  if (field.widget === 'month') {
    return <MonthOutputWidget value={v} />;
  }

  if (field.type === 'date') {
    return <DateWidget value={v} readOnly={true} />;
  }

  if (field.type === 'date_list') {
    return <DateListOutputWidget value={v} />;
  }

  if (field.widget === 'image') {
    return renderImage(field, value);
  }

  if (field.widget === 'checkbox') {
    return <CheckboxField value={value} field={field} readOnly={true} />;
  }

  const textAlign = widgetProps<'left' | 'right' | 'center'>(
    field,
    'text_align',
    'left',
  );

  return (
    <Text title={v} style={{...style, textAlign}}>
      {v}
    </Text>
  );
}

function renderCell(
  schema: Schema,
  field: Field,
  options: OptionMaps,
  relatedResources: RelatedResources,
  files: AttachmentFiles,
  cellProps: CellProps<Value>,
  props: Props,
  column: TableColumn,
) {
  if (field.type === 'file') {
    return renderFile(schema, field, files, cellProps);
  }

  const value = renderValue(
    field,
    options,
    relatedResources,
    cellProps,
    props,
    column,
  );

  if (!value) {
    return null;
  }

  return <Cell style={makeStyle(cellProps.row.original)}>{value}</Cell>;
}

function makeStyle(item?: ResourceDetails) {
  return makeStyleForDeleted(item && item.is_deleted);
}

export type StickyColumn = Column<ResourceDetails> & {
  sticky?: 'left' | 'right';
};

function buildColumns(
  props: Props,
  resourceList: ResourceList,
  columns: TableColumn[],
  expandLastColumn: boolean,
  checkbox?: boolean,
  checkedIds?: Set<string>,
  onCheck?: (ids: Set<string>, checked: boolean) => void,
  defaultColumnWidth?: number,
): StickyColumn[] {
  const cs: Column<ResourceDetails>[] = [];

  if (checkbox && onCheck && checkedIds) {
    cs.push(buildCheckboxColumn(checkedIds, onCheck));
  }

  const schema = resourceList.schema;
  const fields = buildFieldMap(schema.fields);
  const options = buildOptionMaps(resourceList.schema.fields);

  let hasExpandableColumn = false;

  for (let col of columns) {
    const field = fields[col.fieldId];

    if (!field) {
      continue;
    }

    if (col.minWidth) {
      hasExpandableColumn = true;
    }

    cs.push(
      buildFieldColumn(
        field,
        col,
        props,
        resourceList,
        options,
        defaultColumnWidth,
      ),
    );
  }

  if (!hasExpandableColumn && !expandLastColumn) {
    cs.push(nullColumn);
  }

  return cs;
}

function buildCheckboxColumn(
  checkedIds: Set<string>,
  onCheck: (ids: Set<string>, checked: boolean) => void,
): Column<ResourceDetails> {
  return {
    Header: (headerProps: HeaderProps<ResourceDetails>) => {
      const ids = new Set<string>([...headerProps.rows.map((r) => r.id)]);
      const checked = ids.size > 0 && minus(ids, checkedIds).size === 0;

      return (
        <RowCheckbox
          onCheck={(checked: boolean) => {
            onCheck(ids, checked);
          }}
          checked={checked}
        />
      );
    },
    Cell: (cellProps: CellProps<ResourceDetails>) => {
      const id = cellProps.row.id;
      const checked = checkedIds.has(id);

      return (
        <RowCheckbox
          onCheck={(checked: boolean) => {
            onCheck(new Set<string>([id]), checked);
          }}
          checked={checked}
        />
      );
    },
    width: 40,
    accessor: '__checkbox__',
  };
}

function buildFieldColumn(
  field: Field,
  col: TableColumn,
  props: Props,
  resourceList: ResourceList,
  options: OptionMaps,
  defaultColumnWidth?: number,
): Column<ResourceDetails> {
  const schema = resourceList.schema;
  const relatedResources = resourceList.relatedResources || {};
  const files = resourceList.attachmentFiles || {};

  const c: Column<ResourceDetails> = {
    Header: (headerProps: HeaderProps<ResourceDetails>) => {
      return renderHeaderCell(field, col, headerProps, props);
    },
    Cell: (cellProps: CellProps<ResourceDetails>) => {
      return renderCell(
        schema,
        field,
        options,
        relatedResources,
        files,
        cellProps,
        props,
        col,
      );
    },
    accessor: field.id,
  };

  if (col.minWidth) {
    c.minWidth = col.minWidth;
  } else {
    c.width = col.width || defaultColumnWidth || DEFAULT_COLUMN_WIDTH;
  }

  return c;
}

function buildFieldMap(fields: Field[]): FieldMap {
  const map: FieldMap = {};

  for (let field of fields) {
    map[field.id] = field;
  }

  return map;
}

function buildOptionMaps(fields: Field[]): OptionMaps {
  const options: OptionMaps = {};

  for (let field of fields) {
    if (field.options) {
      const opts: OptionMap = {};

      for (let o of field.options) {
        opts[o.value] = o.label;
      }

      options[field.id] = opts;
    }
  }

  return options;
}

function buildRows(resourceList: ResourceList): ResourceDetails[] {
  return resourceList.list;
}

function chooseVisibleValue(
  value: any,
  field: Field,
  options: OptionMaps,
  relatedResources: RelatedResources,
): any {
  if (Array.isArray(value)) {
    const values = array(value);
    return values.map((v) =>
      chooseVisibleValue(v, field, options, relatedResources),
    );
  }

  if (
    (field.type === 'resid' || field.type === 'resid_list') &&
    relatedResources[value]
  ) {
    const fid = widgetProps<string>(field, 'name_field_id', 'name');
    return relatedResources[value][fid] || '';
  }

  if (options[field.id]) {
    return options[field.id][value];
  }

  return String(value);
}

function renderFile(
  schema: Schema,
  field: Field,
  files: AttachmentFiles,
  cellProps: CellProps<ResourceDetails>,
) {
  const value = cellProps.cell.value;

  if (!value) {
    return null;
  }

  const column = cellProps.column;
  return mainImage(value, column, schema, field, files);
}

function mainImage(
  value: any,
  column: Column<ResourceDetails>,
  schema: Schema,
  field: Field,
  files: AttachmentFiles,
) {
  for (let v of array(value)) {
    const file = files[v];

    if (!file) {
      continue;
    }

    if (/^image/.test(file.contentType)) {
      const url = `/app/${schema.id}/${file.resourceId}/file/${file.id}`;
      return (
        <ImageCell>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Image
              key={`file-${file.id}`}
              src={url}
              size={parseInt(String(column.width), 10) - 20}
            />
          </a>
        </ImageCell>
      );
    }
  }

  return null;
}

type ResourceListSetter = (list: ResourceList) => void;

const reloadList = async (
  ctx: ApiContext,
  newParams: {[key: string]: any},
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
) => {
  if (!list) {
    return;
  }

  const params: Params = list.params || {};

  Object.keys(newParams).forEach((k) => {
    params[k] = newParams[k];
  });

  const validParams = ignoreEmpty(params, Object.keys(defaultParams || {}));

  const resourceList = await api.list(ctx, list.schema.id, validParams);
  setter(resourceList);
};

async function onChangeCurrentPage(
  ctx: ApiContext,
  page: number,
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
) {
  await reloadList(
    ctx,
    {
      [PARAM_KEY_PAGE]: page,
    },
    setter,
    list,
    defaultParams,
  );
}

async function onChangePageSize(
  ctx: ApiContext,
  size: number,
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
) {
  await reloadList(
    ctx,
    {
      [PARAM_KEY_SIZE]: size,
      [PARAM_KEY_PAGE]: 0, // reset page select
    },
    setter,
    list,
    defaultParams,
  );
}

async function onChangeSorter(
  ctx: ApiContext,
  id: string,
  current: SortOrder,
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
) {
  const order =
    current.sort === id ? (current.order === 'desc' ? 'asc' : 'desc') : 'desc';

  await reloadList(
    ctx,
    {
      [PARAM_KEY_SORT]: id,
      [PARAM_KEY_ORDER]: order,
    },
    setter,
    list,
    defaultParams,
  );
}

async function onChangeFilter(
  ctx: ApiContext,
  values: FormValues,
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
) {
  await reloadList(ctx, values, setter, list, defaultParams);
}

type PagerProps = {
  onChangeCurrentPage: (page: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeSorter: (id: string, current: SortOrder) => void;
  onChangeFilter: (values: FormValues) => void;
};

export function buildPagerProps(
  ctx: ApiContext,
  setter: ResourceListSetter,
  list?: ResourceList,
  defaultParams?: Params,
): PagerProps {
  return {
    onChangeCurrentPage: async (page: number) => {
      await onChangeCurrentPage(ctx, page, setter, list, defaultParams);
    },
    onChangePageSize: async (size: number) => {
      await onChangePageSize(ctx, size, setter, list, defaultParams);
    },
    onChangeSorter: async (id: string, current: SortOrder) => {
      await onChangeSorter(ctx, id, current, setter, list, defaultParams);
    },
    onChangeFilter: async (values: FormValues) => {
      await onChangeFilter(ctx, values, setter, list, defaultParams);
    },
  };
}

export function buildEmptyPagerProps(): PagerProps {
  return {
    onChangeCurrentPage: (page: number) => {},
    onChangePageSize: (size: number) => {},
    onChangeSorter: (id: string, current: SortOrder) => {},
    onChangeFilter: (values: FormValues) => {},
  };
}

function renderImage(field: Field, value: any) {
  return (
    <ImageOutputWidget
      value={value}
      alt={field.label}
      size={80}
      styles={{
        root: {margin: '0'},
        img: {borderWidth: '2px'},
        notFound: {borderWidth: '2px'},
      }}
    />
  );
}

type RowCheckboxProps = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
};

function RowCheckbox(props: RowCheckboxProps): JSX.Element | null {
  return (
    <CheckboxContainer
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onCheck(!props.checked);
      }}>
      <Checkbox checked={props.checked} />
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function productSet(list: ResourceList, ids: Set<string>): Set<string> {
  if (ids.size === 0) {
    return ids;
  }

  const result = new Set<string>();

  for (let details of list.list) {
    if (ids.has(details.id)) {
      result.add(details.id);
    }
  }

  return result;
}
