import {useEffect, useState} from 'react';
import {api, useApiContext} from '../../api';
import {useMessage} from '../../hooks/useMessage';
import {Field} from '../../types/Field';
import {Params} from '../../types/Params';
import {RelatedSchemas} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {SchemaWithoutScreen} from '../../types/Schema';
import {widgetProps} from '../../widgets/common';
import {Steps} from '../../widgets/item/Step';

export type ItemProps = {
  title: string;
  steps: Steps;
  itemSchema: SchemaWithoutScreen;
  itemType: string;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  disabledIds: string[];
  candidateTitle: string;
  tree: boolean;
  expand: boolean;
  defaultParams: Params;
  nameFieldId: string;
  capacity: number;
};

export function useItemProps(
  field: Field,
  item?: ResourceDetails,
  relatedSchemas?: RelatedSchemas,
  defaultCapacity?: number,
): ItemProps | null {
  const title = useMessage('Widget.Item.Select', 'Select');
  const candidateTitle = widgetProps<string>(field, 'candidate_title', '');
  const tree = widgetProps<boolean>(field, 'tree', false);
  const expand = widgetProps<boolean>(field, 'expand', false);
  const steps = widgetProps<Steps>(field, 'steps', []);
  const defaultParams = widgetProps<Params>(field, 'params', {});
  const nameFieldId = widgetProps<string>(field, 'name_field_id', '');
  const capacity = widgetProps<number>(field, 'capacity', defaultCapacity || 0);

  const itemType = field.itemType;
  const itemSubTypes = field.itemSubTypes;
  const itemTreeSubTypes = field.itemTreeSubTypes;
  const itemSchema = useSchema(itemType || '', relatedSchemas);

  if (!itemType || !itemSchema) {
    return null;
  }

  const disabledIds = item ? [item.id] : [];

  return {
    title,
    candidateTitle,
    tree,
    expand,
    steps,
    defaultParams,
    nameFieldId,
    capacity,
    itemSchema,
    itemType,
    itemSubTypes,
    itemTreeSubTypes,
    disabledIds,
  };
}

function useSchema(
  schemaId?: string,
  relatedSchemas?: RelatedSchemas,
): SchemaWithoutScreen | undefined {
  const init = relatedSchemas ? relatedSchemas[schemaId || ''] : undefined;
  const [schema, setSchema] = useState<SchemaWithoutScreen | undefined>(init);
  const ctx = useApiContext();

  useEffect(() => {
    (async () => {
      if (schemaId && !schema) {
        const s = await api.fetchSchema(ctx, schemaId, {});
        setSchema(s);
      }
    })();
  }, [schemaId, schema, setSchema, ctx]);

  return schema;
}
