import {DefaultButton, IButtonProps, mergeStyleSets} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {COMMAND_BAR_HEIGHT} from '../../consts';
import {MaterialIcon} from '../../icons/MaterialIcon';
import {
  bodyColorHovered,
  bodyColorPrimary,
  borderColor,
  borderColorLightest,
  textColor,
  textColorInverse,
  textColorThemeLight,
} from '../../styles';

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 500px;
  grid-template-areas: 'filter candidate selected';
  box-sizing: border-box;
  border: 1px solid ${borderColor};
  overflow-x: auto;
`;

export const Header = styled.div`
  height: ${COMMAND_BAR_HEIGHT + 1}px;
  border-bottom: 1px solid ${borderColorLightest};
  background-color: ${bodyColorHovered};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${textColor};
`;

export const HeaderTitleWithButton = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  margin-left: 1rem;
`;

export const FilterColumn = styled.div`
  grid-area: filter;
  position: relative;
  border-right: 1px solid ${borderColor};
`;

export const CandidateColumn = styled.div`
  grid-area: candidate;
  position: relative;
  border-right: 1px solid ${borderColor};
`;

export const SelectedColumn = styled.div`
  grid-area: selected;
  position: relative;

  & ${Header} {
    background-color: ${bodyColorPrimary};
    color: ${textColorInverse};
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AllButton = (props: IButtonProps): JSX.Element | null => {
  return (
    <DefaultButton
      {...props}
      styles={mergeStyleSets(props.styles, {
        root: {
          marginRight: '0.3rem',
        },
      })}
    />
  );
};

export const ButtonContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${textColorThemeLight};
`;

export const BackButton = styled(MaterialIcon)``;
