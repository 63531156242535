import {Checkbox, DefaultButton} from '@fluentui/react';
import * as React from 'react';
import {useCallback} from 'react';
import styled from 'styled-components';
import {useMessage} from '../hooks/useMessage';
import {bodyColor, textColor} from '../styles';
import {Option} from '../types/Field';
import {array} from '../util';

type Props = {
  value: any;
  options: Option[];
  onChange?: OnChangeFn;
  onRenderLabel?: RenderLabelFn;
  showCheckAll?: boolean;
  onCheckAll?: OnChangeAllFn;
  vertical?: boolean;
  readOnly?: boolean;
};

export type OnChangeFn = (v: any, checked?: boolean) => void;
export type OnChangeAllFn = (checked?: boolean) => void;

export function CheckboxWidget(props: Props): JSX.Element {
  const onChange = props.onChange;

  const fn = useCallback<OnChangeFn>(
    (ev, checked) => {
      if (!ev || !onChange) {
        return;
      }

      const v = (ev.target as HTMLInputElement).value;
      onChange(v, checked);
    },
    [onChange],
  );

  const Container = props.vertical ? VerticalContainer : HorizontalContainer;
  const values = array(props.value);

  return (
    <Wrapper>
      {props.showCheckAll && props.onCheckAll && !props.readOnly ? (
        <SelectAll {...props} values={values} onCheckAll={props.onCheckAll!} />
      ) : null}
      <Container>
        {props.options!.map((option, i) => {
          const isChecked = values.includes(String(option.value));

          return (
            <OptionContainer key={`option-${i}`}>
              <Checkbox
                inputProps={{value: option.value}}
                label={option.label}
                checked={isChecked}
                onChange={fn}
                disabled={props.readOnly}
                onRenderLabel={buildRenderer(option, props.onRenderLabel)}
              />
            </OptionContainer>
          );
        })}
      </Container>
    </Wrapper>
  );
}

type SelectAllProps = {
  values: string[];
  options: Option[];
  onCheckAll: OnChangeAllFn;
};

function SelectAll(props: SelectAllProps): JSX.Element | null {
  const checkAll = useMessage('Widget.Checkbox.CheckAll', 'Check All');
  const uncheckAll = useMessage('Widget.Checkbox.UncheckAll', 'Uncheck All');
  const all = new Set<string>(props.values);
  const isChecked = props.options.every((o) => all.has(o.value));
  const label = isChecked ? uncheckAll : checkAll;

  return (
    <DefaultButton
      text={label}
      onClick={() => {
        props.onCheckAll(!isChecked);
      }}
      styles={{
        root: {
          marginBottom: '0.2rem',
          whiteSpace: 'nowrap',
        },
      }}
    />
  );
}

type Renderer = () => JSX.Element | null;
type RenderLabelFn = (option: Option) => JSX.Element | null;

function buildRenderer(
  option: Option,
  onRenderLabel?: RenderLabelFn,
): Renderer | undefined {
  if (!onRenderLabel) {
    return;
  }

  return () => {
    return onRenderLabel(option);
  };
}

const Wrapper = styled.div``;

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VerticalContainer = styled.div``;

const OptionContainer = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-right: 1.5rem;

  & .is-disabled.is-checked .ms-Checkbox-text {
    color: ${textColor};
  }

  & .is-disabled.is-checked .ms-Checkbox-checkbox {
    background-color: ${textColor};
    border-color: ${textColor};
  }

  & .is-disabled.is-checked .ms-Checkbox-checkmark {
    color: ${bodyColor};
  }
`;
