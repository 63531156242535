import React from 'react';
import {BoolSearchField} from '../fields/BoolField';
import {ComboBoxField} from '../fields/ComboBoxField';
import {ItemField} from '../fields/ItemField';
import {MonthFilterField} from '../fields/MonthFilterField';
import {RadioField} from '../fields/RadioField';
import {TextField} from '../fields/TextField';
import {Actions} from '../types/Action';
import {SelectWidget} from '../widgets/SelectWidget';
import {
  renderCheckboxField,
  renderNull,
  renderNumberField,
} from './CommonField';
import {DateFilterField} from './DateFilterField';
import {OnGetValue, OnSearch} from './FieldComponent';
import {FieldValueProps} from './FieldValue';
import {TimeFilterField} from './TimeFilterField';

export type FilterFieldValueProps = FieldValueProps & {
  onGetValue: OnGetValue;
  onSearch: OnSearch;
  actions: Actions;
};

export function FilterFieldValue(props: FilterFieldValueProps) {
  const render =
    searchWidgetFunctions[props.field.searchWidget!] ||
    widgetFunctions[props.field.widget!] ||
    typeFunctions[props.field.type] ||
    renderTextField;

  return render(props);
}

const renderTextField = (props: FilterFieldValueProps) => {
  return (
    <TextField
      {...props}
      multiline={false}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          props.onSearch();
        }
      }}
    />
  );
};

const renderRadioField = (props: FilterFieldValueProps) => {
  return <RadioField {...props} vertical={true} />;
};

const renderSelectField = (props: FilterFieldValueProps) => {
  return <SelectWidget {...props} multiple={true} />;
};

const renderSingleSelectField = (props: FilterFieldValueProps) => {
  return <SelectWidget {...props} multiple={false} />;
};

const renderItemField = (props: FilterFieldValueProps) => {
  // To avoid propagating unwanted props, {...props} are not used.
  // ItemField has a selection dialog and I want to prevent it from being propagated there.
  return (
    <ItemField
      value={props.value}
      onChange={props.onChange}
      field={props.field}
      actions={props.actions}
      relatedResources={props.relatedResources}
      relatedSchemas={props.relatedSchemas}
      capacity={0}
    />
  );
};

const renderDateField = (props: FilterFieldValueProps) => {
  return <DateFilterField {...props} />;
};

const renderTimeField = (props: FilterFieldValueProps) => {
  return <TimeFilterField {...props} />;
};

const renderMonthField = (props: FilterFieldValueProps) => {
  return <MonthFilterField {...props} />;
};

const renderBoolField = (props: FilterFieldValueProps) => {
  return <BoolSearchField {...props} />;
};

const renderComboBoxField = (props: FilterFieldValueProps) => {
  return <ComboBoxField {...props} />;
};

type RenderFilterFieldFn = (props: FilterFieldValueProps) => JSX.Element | null;

const searchWidgetFunctions: {[key: string]: RenderFilterFieldFn} = {
  string: renderTextField,
  text: renderTextField,
  date: renderDateField,
  checkbox: renderCheckboxField,
  select: renderSelectField,
  single_select: renderSingleSelectField,
  radio: renderRadioField,
  combobox: renderComboBoxField,
  scanner: renderNull,
  hidden: renderNull,
};

const widgetFunctions: {[key: string]: RenderFilterFieldFn} = {
  string: renderTextField,
  text: renderTextField,
  checkbox: renderCheckboxField,
  select: renderSelectField,
  single_select: renderSingleSelectField,
  radio: renderCheckboxField,
  month: renderMonthField,
  time: renderTimeField,
  combobox: renderComboBoxField,
  scanner: renderNull,
  hidden: renderNull,
};

const typeFunctions: {[key: string]: RenderFilterFieldFn} = {
  string: renderTextField,
  int: renderNumberField,
  float: renderNumberField,
  serial: renderTextField,
  date: renderDateField,
  date_list: renderDateField,
  resid: renderItemField,
  resid_list: renderItemField,
  text: renderTextField,
  bool: renderBoolField,
};
