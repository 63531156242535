import {DefaultButton} from '@fluentui/react';
import React, {CSSProperties, useRef, useState} from 'react';
import styled from 'styled-components';
import {useApiContext} from '../api';
import {Dialog} from '../common/Dialog';
import {ItemDetailsDialog} from '../common/dialogs/ItemDetailsDialog';
import {useMessage} from '../hooks/useMessage';
import {bodyColor, borderColorLight, textColor} from '../styles';
import {Actions} from '../types/Action';
import {Option} from '../types/Field';
import {Params} from '../types/Params';
import {RelatedResources} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {SchemaWithoutScreen} from '../types/Schema';
import {WidgetStyles} from '../types/WidgetStyles';
import {array} from '../util';
import {CheckboxWidget} from './CheckboxWidget';
import {ClearButton} from './common/ClearButton';
import {Item, makeStyleForDeleted} from './item/Item';
import {ItemSelectorDialog} from './item/ItemSelectorDialog';
import {Steps} from './item/Step';

type Props = {
  value: string[];
  onChange: (ids: string[]) => void;
  checked: string[];
  onCheck: (ids: string[]) => void;
  onClear: () => void;
  title: string;
  steps: Steps;
  itemSchema: SchemaWithoutScreen;
  itemType: string;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  disabledIds: string[];
  actions: Actions;
  capacity?: number;
  styles?: WidgetStyles;
  candidateTitle: string;
  tree: boolean;
  expand: boolean;
  defaultParams: Params;
  nameFieldId: string;
  relatedResources?: RelatedResources;
  checkboxDescription?: string;
  checkboxDescriptionView?: string;
  readOnly?: boolean;
};

export function ItemCheckboxWidget(props: Props): JSX.Element {
  if (props.readOnly) {
    return <ItemCheckboxOutputWidget {...props} />;
  }

  return <ItemCheckboxInputWidget {...props} />;
}

export function ItemCheckboxOutputWidget(props: Props): JSX.Element {
  const {items, itemsMap} = useItems(props);
  const options = buildOptions(items);
  const [itemId, setItemId] = useState<string>();
  const [schemaId, setSchemaId] = useState<string>(props.itemType);
  const [shown, setShown] = useState<boolean>(false);

  const ctx = useApiContext();

  return (
    <Container>
      {items.length > 0 ? (
        <>
          <CheckboxDescription
            text={props.checkboxDescriptionView || props.checkboxDescription}
          />
          <CheckboxWidget
            value={props.checked}
            options={options}
            readOnly={true}
            onRenderLabel={(option) => {
              const style = makeStyle(itemsMap[option.value]);

              return (
                <Label
                  option={option}
                  itemsMap={itemsMap}
                  nameFieldId={props.nameFieldId}
                  onClick={async () => {
                    await setItemId(option.value);

                    if (
                      itemsMap[option.value] &&
                      itemsMap[option.value].schema_id
                    ) {
                      await setSchemaId(itemsMap[option.value].schema_id);
                    }

                    setShown(true);
                  }}
                  style={{
                    ...style,
                    cursor: 'pointer',
                  }}
                />
              );
            }}
          />
          <ItemDetailsDialog
            ctx={ctx}
            shown={shown}
            schemaId={schemaId}
            resId={itemId}
            onClose={() => {
              setShown(false);
            }}
            actions={props.actions}
            minWidth={'400px'}
          />
        </>
      ) : null}
    </Container>
  );
}

export function ItemCheckboxInputWidget(props: Props): JSX.Element {
  const dialog = useRef<Dialog>(null);
  const {onChange, items, itemsMap} = useItems(props);
  const options = buildOptions(items);

  return (
    <Container>
      <DefaultButton
        text={useMessage('Widget.Item.Select', 'Select')}
        onClick={() => {
          if (dialog.current) {
            dialog.current.showDialog();
          }
        }}
        styles={{
          root: {
            marginRight: '0.5rem',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <ClearButton onClick={props.onClear} />
      <ItemSelectorDialog
        {...props}
        componentRef={dialog}
        value={items}
        onSelect={onChange}
      />
      {items.length > 0 ? (
        <CheckboxContainer>
          <CheckboxDescription text={props.checkboxDescription} />
          <CheckboxWidget
            value={props.checked}
            options={options}
            onChange={(id, checked) => {
              onChecked(props, id, checked);
            }}
            onRenderLabel={(option) => {
              return (
                <Label
                  option={option}
                  itemsMap={itemsMap}
                  nameFieldId={props.nameFieldId}
                />
              );
            }}
            showCheckAll={true}
            onCheckAll={(checked) => {
              if (checked) {
                props.onCheck(props.value);
              } else {
                props.onCheck([]);
              }
            }}
          />
        </CheckboxContainer>
      ) : null}
    </Container>
  );
}

function useItems(props: Props) {
  const [relates, setRelates] = useState<RelatedResources>({
    ...props.relatedResources,
  });

  const onChange = (v: ResourceDetails[]) => {
    const values = array(v).map((x) => x.id);
    const newRelates = {...relates};

    for (let x of array(v)) {
      newRelates[x.id] = x;
    }

    props.onChange(values);
    setRelates(newRelates);
    return true;
  };

  const toItems = (ids: string[]): ResourceDetails[] => {
    return [...new Set(relatedItems(ids, relates))];
  };

  const items = toItems(props.value);
  const itemsMap = toMap(items);

  return {
    items,
    itemsMap,
    onChange,
  };
}

function relatedItems(ids: string[], relates: RelatedResources) {
  if (!ids || !relates) {
    return [];
  }

  return ids.map((id) => relates[id]).filter((item) => !!item);
}

function buildOptions(items: ResourceDetails[]): Option[] {
  return items.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}

type ItemsMap = {[id: string]: ResourceDetails};

function toMap(items: ResourceDetails[]): ItemsMap {
  const m: ItemsMap = {};

  for (let item of items) {
    m[item.id] = item;
  }

  return m;
}

function onChecked(props: Props, id: string, checked?: boolean) {
  const newValues = [...props.checked];
  const index = newValues.indexOf(id);

  if (checked && index < 0) {
    newValues.push(id);
  } else if (index > -1) {
    newValues.splice(index, 1);
  }

  props.onCheck(newValues);
}

type CheckboxDescriptionProps = {
  text?: string;
};

function CheckboxDescription(
  props: CheckboxDescriptionProps,
): JSX.Element | null {
  if (!props.text) {
    return null;
  }

  return <Description>{props.text}</Description>;
}

type LabelProps = {
  option: Option;
  itemsMap: ItemsMap;
  nameFieldId: string;
  onClick?: () => void;
  style?: CSSProperties;
};

function Label(props: LabelProps): JSX.Element {
  return (
    <ItemRowContainer style={props.style} onClick={props.onClick}>
      <Item
        item={props.itemsMap[props.option.value]}
        nameFieldId={props.nameFieldId}
      />
    </ItemRowContainer>
  );
}

function makeStyle(item?: ResourceDetails) {
  return makeStyleForDeleted(item && item.is_deleted);
}

const Container = styled.div``;

const CheckboxContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid ${borderColorLight};
  background-color: ${bodyColor};
`;

const Description = styled.div`
  margin-bottom: 0.5rem;
  color: ${textColor};
`;

const ItemRowContainer = styled.div`
  max-width: 200px;
  margin-left: 4px;
`;
