import React from 'react';
import styled from 'styled-components';
import {bodyColorDark, borderColorLight, textColorLight} from '../styles';

const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: small;
  color: ${textColorLight};
  box-sizing: border-box;
  background-color: ${bodyColorDark};
  border-top: 1px solid ${borderColorLight};
`;

export function Footer() {
  const footer = [...document.head.getElementsByTagName('meta')].find(
    (meta) => meta.name === 'footer',
  );

  return (
    <Container>
      <div>{footer?.content ?? '@SalesOne, inc.'}</div>
    </Container>
  );
}
