import {DefaultButton, PrimaryButton} from '@fluentui/react';
import * as React from 'react';
import {RefObject} from 'react';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {ApiError} from '../ApiError';
import {Dialog} from '../common/Dialog';
import {ErrorMsg} from '../common/ErrorMsg';
import {ResponseDialogComponent} from '../common/ResponseDialog';
import {PARAM_KEY_FORCE} from '../consts';
import {convertFromFormValues, convertToFormValues} from '../field/Converter';
import {FormFieldContainer} from '../field/FieldComponent';
import {FieldValues, OnChangeFieldValue} from '../field/FieldValue';
import {Sections} from '../sections/Sections';
import {bodyColor, borderColorLightest} from '../styles';
import {Actions} from '../types/Action';
import {AttachmentFile} from '../types/AttachmentFile';
import {Errors} from '../types/Errors';
import {Field} from '../types/Field';
import {FormFiles} from '../types/Form';
import {RelatedProxies, RelatedResources, Resource} from '../types/Resource';
import {ResponseDialog} from '../types/ResponseDialog';
import {Schema} from '../types/Schema';
import {Section} from '../types/Section';

export type Value = any;
export type Values = {[key: string]: Value};
export type RelatedValues = {[fieldId: string]: Values[]};
export type OnSaveFunc = (
  values: Values,
  files: FormFiles,
  relatedValues: RelatedValues,
) => Promise<void>;

type Props = {
  ctx: ApiContext;
  resource: Resource;
  sections?: Section[];
  labelPosition?: string;
  fieldSeparator?: boolean;
  onCancel: () => void;
  onSave: OnSaveFunc;
  actions: Actions;
  saveButtonLabel?: string;
};

type State = {
  status: 'saving' | 'ready';
  values: Values;
  schema: Schema;
  relatedValues: RelatedValues;
  files: FormFiles;
  relatedProxies: RelatedProxies;
  relatedResources: RelatedResources;
  errors: Errors;
  errorDialog: ResponseDialog | null;
};

export class ItemForm extends React.Component<Props, State> {
  private readonly errorDialog: RefObject<Dialog>;

  constructor(props: Props) {
    super(props);

    this.errorDialog = React.createRef();

    this.state = {
      status: 'ready',
      values: convertToFormValues(props.resource),
      schema: props.resource.schema,
      relatedValues: {},
      files: {},
      relatedProxies: {},
      relatedResources: {},
      errors: {},
      errorDialog: null,
    };
  }

  get disabled() {
    return this.state.status === 'saving';
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onSave = async () => {
    if (!this.props.onSave) {
      return;
    }

    //TODO Filter editable fields only?
    // const values = this.state.schema.fields
    //   .filter((f) => f.editable)
    //   .reduce((values, field) => {
    //     values[field.id] = this.state.values[field.id];
    //     return values;
    //   }, {} as Values);

    try {
      this.setState({status: 'saving'});
      await this.props.onSave(
        convertFromFormValues(this.state.schema, this.state.values),
        this.state.files,
        this.state.relatedValues,
      );
      this.setState({errors: {}, errorDialog: null, status: 'ready'});
    } catch (e) {
      this.handleError(e);
      this.setState({status: 'ready'});
    }
  };

  onForceSave = async () => {
    if (!this.props.onSave) {
      return true;
    }

    try {
      this.setState({status: 'saving'});
      await this.props.onSave(
        {...this.state.values, [PARAM_KEY_FORCE]: true},
        this.state.files,
        this.state.relatedValues,
      );
      this.setState({errors: {}, errorDialog: null, status: 'ready'});
    } catch (e) {
      this.handleError(e);
      this.setState({status: 'ready'});
    }

    return true;
  };

  handleError(e: any) {
    if (e instanceof ApiError) {
      const m = e.getMessageMap();

      this.setState({
        errors: m,
        errorDialog: e.getDialog(),
      });

      if (e.getDialog() || m['_warning'] !== undefined) {
        this.errorDialog.current!.showDialog();
      }
    }
  }

  updateValues = (field: Field, value: any) => {
    const values = {
      ...this.state.values,
      [field.id]: value,
    };

    this.setState({values});

    if (!field.patchTrigger) {
      return;
    }

    this.patch(values);
  };

  patch = async (values: Values) => {
    try {
      const patchedValues = await api.patch(
        this.props.ctx,
        this.state.schema.id,
        this.props.resource.id,
        convertFromFormValues(this.state.schema, values),
      );

      this.setState({
        values: {...values, ...patchedValues.details},
        schema: patchedValues.schema,
      });
    } catch (ignore) {}
  };

  updateRelatedValues = (field: Field, value: any) => {
    this.setState({
      relatedValues: {
        ...this.state.relatedValues,
        [field.id]: value,
      },
    });
  };

  updateFiles = (field: Field, value: File[]) => {
    this.setState({
      files: {
        ...this.state.files,
        [field.id]: value,
      },
    });
  };

  renderFields = () => {
    return this.state.schema.fields
      .filter(this.isVisible)
      .map((field) => this.renderField(field))
      .filter((c) => c !== null);
  };

  isVisible = (field: Field) => {
    if (field.widget === 'scanner') {
      return false;
    }

    if (field.widget === 'table') {
      return field.editable;
    }

    return field.editable || field.visible;
  };

  onChangeFieldValue: OnChangeFieldValue = (v: FieldValues) => {
    const values = {
      ...this.state.values,
      ...v,
    };

    this.setState({values});

    if (!containsPatchTrigger(this.state.schema, Object.keys(v))) {
      return;
    }

    this.patch(values);
  };

  renderField = (field: Field, label?: string, labelPosition?: string) => {
    const fns: {[key: string]: OnChangeFieldValue} = {
      table: (v) => {
        this.updateRelatedValues(field, v[field.id]);
      },
      file: (v, isNewFile) => {
        if (isNewFile) {
          this.updateFiles(field, v[field.id]);
          return;
        }

        const fs: AttachmentFile[] = v[field.id];
        const ids = fs.map((f) => f.id);
        this.updateValues(field, ids);
      },
    };

    const onChange: OnChangeFieldValue =
      fns[field.widget] || fns[field.type] || this.onChangeFieldValue;

    return (
      <FormFieldContainer
        key={`field-${field.id}`}
        ctx={this.props.ctx}
        value={this.state.values[field.id]}
        values={this.state.values}
        field={field}
        resource={this.props.resource}
        label={label}
        labelPosition={labelPosition || this.props.labelPosition}
        fieldSeparator={this.props.fieldSeparator ?? true}
        onChange={onChange}
        messages={this.state.errors[field.id]}
        actions={this.props.actions}
        errors={this.state.errors}
      />
    );
  };

  renderSpecialError = () => {
    if (this.state.errors['_']) {
      return (
        <SpecialError>
          <ErrorMsg messages={this.state.errors['_']} />
        </SpecialError>
      );
    }

    return null;
  };

  renderButtons = () => {
    const disabled = this.disabled;

    return (
      <Buttons>
        <PrimaryButton
          text={this.props.saveButtonLabel || '保存'}
          onClick={this.onSave}
          disabled={disabled}
          styles={{root: {marginRight: '1rem'}}}
        />
        <DefaultButton
          text={'キャンセル'}
          onClick={this.onCancel}
          disabled={disabled}
        />
      </Buttons>
    );
  };

  renderSections() {
    return (
      <Sections
        sections={this.props.sections}
        schema={this.state.schema}
        resource={this.props.resource}
        renderField={this.renderField}
        isAvailable={this.isVisible}
      />
    );
  }

  renderContents() {
    if (this.props.sections && this.props.sections.length > 0) {
      return this.renderSections();
    }

    return this.renderFields();
  }

  renderConfirmDialog() {
    const dialog = this.state.errorDialog;

    if (!dialog) {
      return (
        <Dialog
          ref={this.errorDialog}
          modal={true}
          title={'警告'}
          onExecute={this.onForceSave}
          executeLabel={'警告を無視して保存'}
          onCancel={() => {}} // show cancel button
          disabled={this.disabled}>
          <ConfirmDialogContents>
            <ErrorMsg messages={this.state.errors['_warning']} />
          </ConfirmDialogContents>
        </Dialog>
      );
    }

    const msg = dialog.message
      ? [dialog.message]
      : this.state.errors['_warning'];

    const actions = {
      force_save: this.onForceSave,
      cancel: () => {
        this.errorDialog.current?.closeDialog();
      },
    };

    return (
      <ResponseDialogComponent
        dialog={dialog}
        componentRef={this.errorDialog}
        modal={true}
        defaultTitle={'警告'}
        disabled={this.disabled}
        renderBody={() => {
          return (
            <ConfirmDialogContents>
              <ErrorMsg messages={msg} />
            </ConfirmDialogContents>
          );
        }}
        actions={actions}
      />
    );
  }

  render() {
    if (!this.props.resource) {
      return null;
    }

    return (
      <>
        <Container>
          <Contents>{this.renderContents()}</Contents>
          <Footer>
            {this.renderSpecialError()}
            {this.renderButtons()}
          </Footer>
        </Container>
        {this.renderConfirmDialog()}
      </>
    );
  }
}

export function containsPatchTrigger(
  schema: Schema,
  fieldIds: string[],
): boolean {
  for (let fieldId of fieldIds) {
    for (let field of schema.fields) {
      if (fieldId === field.id && field.patchTrigger) {
        return true;
      }
    }
  }

  return false;
}

const Container = styled.form`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Contents = styled.div`
  background-color: white;
  padding: 0.5rem;
  overflow: auto;
  flex-shrink: 1;
`;

const Footer = styled.div`
  border-top: 1px dashed ${borderColorLightest};
  padding: 1rem;
  background-color: ${bodyColor};
  z-index: 1000;
`;

const SpecialError = styled.div`
  margin-bottom: 1rem;
`;

const Buttons = styled.div``;

const ConfirmDialogContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-height: calc(100vh - 300px);
  overflow: auto;
  overflow-x: hidden;
`;
