import {ICommandBarItemProps} from '@fluentui/react';
import {buildConfigUrl} from '../../api';
import {ToolButton} from '../../types/ToolButton';

type ButtonTemplate = ICommandBarItemProps & {
  isValid: (button: ToolButton) => boolean;
};

export const buttonTemplates: {[key: string]: ButtonTemplate} = {
  create: {
    key: 'create',
    name: '作成',
    iconProps: {
      iconName: 'add',
    },
    isValid: () => true,
  },
  create_ref: {
    // need button
    key: 'create_ref',
    name: '作成',
    iconProps: {
      iconName: 'add',
    },
    isValid: () => true,
  },
  edit: {
    key: 'edit',
    name: '編集',
    iconProps: {
      iconName: 'edit-outlined',
    },
    isValid: () => true,
  },
  config: {
    key: 'config',
    name: '設定',
    iconProps: {
      iconName: 'settings',
    },
    isValid: () => true,
    build: (self: ICommandBarItemProps, button: ToolButton) => {
      self.onClick = () => {
        if (button.schemaId) {
          const url = buildConfigUrl(button.schemaId);
          window.location.assign(url);
        }
      };
    },
  },
  copy: {
    key: 'copy',
    name: 'コピー',
    iconProps: {
      iconName: 'file_copy-outlined',
    },
    isValid: () => true,
  },
  show: {
    key: 'show',
    name: '別タブで表示',
    iconProps: {
      iconName: 'open_in_new',
    },
    isValid: () => true,
  },
  delete: {
    key: 'delete',
    name: '削除',
    iconProps: {
      iconName: 'delete_outline',
    },
    isValid: () => true,
  },
  export: {
    key: 'export',
    name: 'エクスポート',
    iconProps: {
      iconName: 'get_app',
    },
    isValid: () => true,
  },
  import: {
    key: 'import',
    name: 'インポート',
    iconProps: {
      iconName: 'publish',
    },
    isValid: () => true,
  },
  download: {
    key: 'download',
    name: 'ダウンロード',
    iconProps: {
      iconName: 'get_app',
    },
    isValid: () => true,
  },
  link: {
    key: 'link',
    name: 'リンク',
    iconProps: {
      iconName: 'open_in_new',
    },
    isValid: () => true,
  },
  request: {
    key: 'request',
    name: 'リクエスト',
    iconProps: {
      iconName: 'radio_button_unchecked',
    },
    isValid: () => true,
    build: (self: ICommandBarItemProps, button: ToolButton) => {
      self.onClick = () => {
        if (button.onClick) {
          button.onClick(button);
        }
      };
    },
  },
  sort: {
    key: 'sort',
    text: '並び順',
    iconProps: {
      iconName: 'Sort',
    },
    isValid: (button: ToolButton) =>
      !!(button.sorters && button.sorters.length > 0),
    build: (self: ICommandBarItemProps, button: ToolButton) => {
      if (!button.sorters) {
        return;
      }

      const items = [];
      const sort = button.currentSort || {sorterId: '', desc: false};

      for (let sorter of button.sorters) {
        const icon =
          sort.sorterId === sorter.id
            ? sort.desc
              ? 'SortUp'
              : 'SortDown'
            : '';

        items.push({
          key: `sort-${sorter.id}`,
          text: sorter.label,
          iconProps: {
            iconName: icon,
          },
          onClick: () => {
            if (button.onClick) {
              button.onClick(button, sorter);
            }
          },
        });
      }

      self.subMenuProps = {items};
    },
  },
  search: {
    key: 'search',
    text: '検索',
    iconProps: {
      iconName: 'Search',
    },
    isValid: () => true,
  },
  batch_edit: {
    key: 'batch_edit',
    text: '一括編集',
    iconProps: {
      iconName: 'Edit',
    },
    isValid: () => true,
  },
};
