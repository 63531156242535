import {Property} from 'csstype';
import React, {MouseEventHandler, useEffect, useMemo, useState} from 'react';
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableBodyProps,
  TableCommonProps,
  TableInstance,
  TableOptions,
  useBlockLayout,
  useExpanded,
  useTable,
} from 'react-table';
import {useSticky} from 'react-table-sticky';
import styled, {css} from 'styled-components';
import {COMMAND_BAR_HEIGHT} from '../../consts';
import {
  bodyColor,
  bodyColorAccent,
  bodyColorAccentHovered,
  bodyColorAlt,
  bodyColorDisabled,
  bodyColorHovered,
  borderColorLightest,
  textColor,
} from '../../styles';
import {TableColumn} from '../../types/TableColumn';
import {makeRowStyle} from '../style/style';
import {needTableTools, TableToolbarProps, TableTools} from './TableTools';

export type Rowable = {
  id: string;
  __row_id__?: string;
  [key: string]: any;
};

export type headerDecorator = (header: JSX.Element) => JSX.Element;

type Props<T extends Rowable> = {
  hideHeader?: boolean;
  columns: Column<T>[];
  rows: T[];
  onSelectRow: (row: T) => void;
  emptyMessage?: string;
  disabledIds?: string[];
  selectedIds?: string[];
  lockedIds?: string[];
  checkedIds?: Set<string>;
  expanded?: {[key: string]: boolean};
  headerDecorator?: headerDecorator;
} & TableToolbarProps;

export function TreeTable<T extends Rowable>(props: Props<T>) {
  return renderTable<T>(
    props,
    useTable<T>(
      {
        columns: neverEmpty(props.columns),
        data: props.rows,
        autoResetExpanded: false,
        getRowId: (row) => row.__row_id__ ?? row.id,
        initialState: {
          expanded: props.expanded || {},
        },
      } as TableOptions<T>,
      useBlockLayout,
      useSticky,
      useExpanded,
    ),
  );
}

export function Table<T extends Rowable>(props: Props<T>) {
  return renderTable<T>(
    props,
    useTable<T>(
      {
        columns: neverEmpty(props.columns),
        data: props.rows,
        getRowId: (row) => row.__row_id__ ?? row.id,
      },
      useBlockLayout,
      useSticky,
    ),
  );
}

export const nullColumn: Column<Rowable> = {
  Header: () => {
    return null;
  },
  Cell: () => {
    return null;
  },
  id: '__null_column__',
  width: 0,
  minWidth: -1,
};

const neverEmptyColumns = [nullColumn];

function neverEmpty<T extends Rowable>(columns: Column<T>[]): Column<T>[] {
  if (columns.length > 0) {
    return columns;
  }

  return neverEmptyColumns as Column<T>[];
}

function renderTable<T extends Rowable>(
  props: Props<T>,
  tableInstance: TableInstance<T>,
) {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    tableInstance;

  return (
    <Container>
      <TableContainer {...getTableProps()}>
        <Header<T>
          {...props}
          groups={headerGroups}
          hidden={!!props.hideHeader}
        />
        <Body<T>
          {...props}
          tableProps={getTableBodyProps()}
          rows={rows}
          prepareRow={prepareRow}
          columns={props.currentColumns || []}
        />
      </TableContainer>
    </Container>
  );
}

type HeaderProps<T extends Rowable> = {
  groups: HeaderGroup<T>[];
  hidden: boolean;
} & Props<T>;

type Width = Property.Width<string | number>;

function Header<T extends Rowable>(props: HeaderProps<T>) {
  return (
    <HeaderContainer>
      <HeaderToolbar {...props} />
      {props.hidden
        ? null
        : props.groups.map((group) => {
            const groupProps = group.getHeaderGroupProps();
            adjustWidth(groupProps);

            return (
              <HeaderRow {...groupProps}>
                {group.headers.map((column) => {
                  const props = column.getHeaderProps();
                  const style = {
                    ...props.style,
                    flexGrow: selectFlexGrow<T>(column),
                  };

                  return (
                    <HeaderCell {...props} style={style}>
                      {column.render('Header')}
                    </HeaderCell>
                  );
                })}
              </HeaderRow>
            );
          })}
    </HeaderContainer>
  );
}

function HeaderToolbar<T extends Rowable>(
  props: HeaderProps<T>,
): JSX.Element | null {
  const [width, setWidth] = useState<Width>();

  useEffect(() => {
    setWidth(props.groups[0]?.getHeaderGroupProps().style?.width);
  }, [props.groups]);

  if (!needTableTools(props)) {
    return null;
  }

  const toolbar = <Toolbar {...props} />;
  const header = props.headerDecorator
    ? props.headerDecorator(toolbar)
    : toolbar;

  return (
    <HeaderToolbarContainer style={{minWidth: width}}>
      <HeaderToolbarCell>{header}</HeaderToolbarCell>
    </HeaderToolbarContainer>
  );
}

function Toolbar<T extends Rowable>(props: HeaderProps<T>) {
  return (
    <TableToolsContainer>
      <TableTools
        {...props}
        style={{padding: '0.3rem', minHeight: COMMAND_BAR_HEIGHT + 'px'}}
      />
    </TableToolsContainer>
  );
}

type BodyProps<T extends Rowable> = {
  tableProps: TableBodyProps;
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  onSelectRow: (row: T) => void;
  columns: TableColumn[];
  emptyMessage?: string;
  disabledIds?: string[];
  selectedIds?: string[];
  lockedIds?: string[];
  checkedIds?: Set<string>;
};

function Body<T extends Rowable>(props: BodyProps<T>) {
  if (props.rows.length === 0) {
    return <EmptyMessageBody message={props.emptyMessage} />;
  }

  return (
    <BodyContainer {...props.tableProps}>
      {props.rows.map((row) => {
        const fn = () => {
          props.onSelectRow(row.original);
        };

        const isSelected = (props.selectedIds || []).includes(row.original.id);
        const isDisabled = (props.disabledIds || []).includes(row.original.id);
        const isLocked = (props.lockedIds || []).includes(row.original.id);
        const isChecked = props.checkedIds
          ? props.checkedIds.has(row.original.id)
          : false;

        return (
          <BodyRow
            key={`row-${row.original.id}`}
            row={row}
            prepareRow={props.prepareRow}
            onSelect={fn}
            isDisabled={isDisabled}
            isSelected={isSelected}
            isLocked={isLocked}
            isChecked={isChecked}
            columns={props.columns}
          />
        );
      })}
    </BodyContainer>
  );
}

function EmptyMessageBody({message}: {message?: string}) {
  const msg = message || 'データがありません';

  return (
    <BodyContainer>
      <tr>
        <td>
          <EmptyMessage>{msg}</EmptyMessage>
        </td>
      </tr>
    </BodyContainer>
  );
}

type BodyRowProps<T extends Rowable> = {
  row: Row<T>;
  prepareRow: (row: Row<T>) => void;
  onSelect: () => void;
  isSelected: boolean;
  isDisabled: boolean;
  isLocked: boolean;
  isChecked: boolean;
  columns: TableColumn[];
};

export const TREE_ARROW_COLUMN_ID = '__tree_arrow__';

function renderCell<T extends Rowable>(cell: Cell<T>) {
  const props = cell.getCellProps();
  const style = {
    ...props.style,
    display: 'flex',
    alignItems: 'center',
    flexGrow: selectFlexGrow<T>(cell.column),
  };

  return (
    <BodyCell {...props} style={style}>
      {cell.render('Cell')}
    </BodyCell>
  );
}

function selectFlexGrow<T extends Rowable>(col: ColumnInstance<T>): number {
  if (col.minWidth === 0) {
    return 0;
  }

  return 1;
}

function renderTreeCell<T extends Rowable>(cell: Cell<T>) {
  if (cell.column.id === TREE_ARROW_COLUMN_ID) {
    return (
      <TransparentBodyCell {...cell.getCellProps()}>
        {cell.render('Cell')}
      </TransparentBodyCell>
    );
  }

  return renderCell<T>(cell);
}

function BodyRow<T extends Rowable>(props: BodyRowProps<T>) {
  const isExpanded = (props.row as any).isExpanded;

  return useMemo(() => {
    props.prepareRow(props.row);

    const render =
      (props.row as any).getToggleRowExpandedProps !== undefined
        ? renderTreeCell
        : renderCell;

    const rowProps = props.row.getRowProps();

    adjustWidth(rowProps);

    const RowComponent = props.isDisabled
      ? DisabledBodyRowContainer
      : props.isSelected
      ? SelectedBodyRowContainer
      : props.isLocked
      ? LockedBodyRowContainer
      : BodyRowContainer;

    const onClick: MouseEventHandler =
      props.isDisabled || props.isLocked
        ? (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
        : props.onSelect;

    const style = {
      ...rowProps.style,
      ...makeRowStyle(props.row.original),
    };

    return (
      <RowComponent {...rowProps} onClick={onClick} style={style}>
        {props.row.cells.map((cell) => {
          return render(cell);
        })}
      </RowComponent>
    );
    //TODO ignore ok?
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.isSelected,
    props.row.original,
    isExpanded,
    props.columns,
    props.isDisabled,
    props.isLocked,
    props.isChecked,
  ]);
}

function adjustWidth(props: TableCommonProps): void {
  if (!props.style) {
    return;
  }

  const w = props.style.width;
  delete props.style.width;
  props.style.minWidth = w;
}

const Container = styled.div`
  width: 100%;
`;

const TableContainer = styled.table`
  width: 100%;
  font-size: 0.9rem;
  border-collapse: collapse;
  & td,
  & th {
    padding: 0;
  }
`;

const HeaderContainer = styled.thead`
  display: block;
  background-color: ${bodyColor};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderToolbarContainer = styled.tr`
  display: block;
  border-bottom: 1px solid ${borderColorLightest};
`;

const HeaderToolbarCell = styled.th`
  display: inline-block;
  box-sizing: border-box;
  position: sticky;
  z-index: 2;
  left: 0;
  font-weight: normal;
`;

const minHeight = '36px';

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${borderColorLightest};
  min-height: ${minHeight};
`;

const cellBaseCss = css`
  box-sizing: content-box;
  border-right: 1px solid ${borderColorLightest};
  color: ${textColor};
  min-height: ${minHeight};
  overflow: hidden;

  &:last-child {
    border-right: 0;
    flex-grow: 1;
  }
`;

const HeaderCell = styled.th`
  ${cellBaseCss};
  font-weight: normal;
  text-align: center;
  min-height: auto;
  background-color: ${bodyColor};
`;

const BodyContainer = styled.tbody`
  position: relative;
  z-index: 0;
`;

const BodyCell = styled.td`
  ${cellBaseCss};
`;

const TransparentBodyCell = styled.td`
  ${cellBaseCss};
  border-right-color: transparent;
`;

const bodyRowCss = css`
  border-bottom: 1px solid ${borderColorLightest};
`;

const BodyRowContainer = styled.tr`
  ${bodyRowCss};

  & td {
    background-color: ${bodyColor};
  }

  &:nth-child(even) td {
    background-color: ${bodyColorAlt};
  }

  &:hover td {
    background-color: ${bodyColorHovered};
  }
`;

const SelectedBodyRowContainer = styled.tr`
  ${bodyRowCss};
  background-color: ${bodyColorAccent};

  &:hover {
    background-color: ${bodyColorAccentHovered};
  }
`;

const LockedBodyRowContainer = styled.tr`
  ${bodyRowCss};

  &:nth-child(even) {
    background-color: ${bodyColorAlt};
  }

  cursor: not-allowed;
`;

const DisabledBodyRowContainer = styled.tr`
  ${bodyRowCss};
  background-color: ${bodyColorDisabled};
  cursor: not-allowed;
`;

const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 1rem;
  box-sizing: border-box;
  color: ${textColor};
`;

const TableToolsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
`;
