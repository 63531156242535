import {Panel, Persona, PersonaSize} from '@fluentui/react';
import {Location} from 'history';
import React, {RefObject, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {api, ApiContext, buildConfigUrl, makeParams, Query} from '../api';
import {ApiError} from '../ApiError';
import {buildCreateRefParams} from '../common/actions/CreateRef';
import {
  buildCheckedItems,
  CheckedItems,
  ItemsMap,
} from '../common/CheckedItems';
import {DateRange, newDate} from '../common/DateRange';
import {Dialog} from '../common/Dialog';
import {AppRequestDialog} from '../common/dialogs/AppRequestDialog';
import {DeleteDialog} from '../common/dialogs/DeleteDialog';
import {ExportDialog} from '../common/dialogs/ExportDialog';
import {ImportDialog} from '../common/dialogs/ImportDialog';
import {ItemFormDialog} from '../common/dialogs/ItemFormDialog';
import {RequestDialog} from '../common/dialogs/RequestDialog';
import {CustomLayout, fold, isFolded} from '../common/DisplayFoldSwitch';
import {ErrorMsg} from '../common/ErrorMsg';
import {
  excludeCommonParams,
  excludeKnownParams,
  setDefaultParams,
  setSortParams,
} from '../common/params';
import {PrimaryHeader} from '../common/PrimaryHeader';
import {buildPagerProps} from '../common/ResourceDetailsTable';
import {extractSections} from '../common/Schema';
import {minus} from '../common/set/set';
import {SimpleList, SimpleListProps} from '../common/SimpleList';
import {Spinner} from '../common/Spinner';
import {Toolbar} from '../common/Toolbar';
import {UIActions} from '../common/uiactions/UIActions';
import {BatchEditForm} from '../components/BatchEditForm';
import {
  OnSelectRowProps,
  selectAreaAndComponent,
  selectComponent,
  selectListComponent,
} from '../components/Components';
import {
  DisplayUnfoldBar,
  DisplayUnfoldBarProps,
} from '../components/DisplayUnfoldBar';
import {Filter, FilterProps, ignoreEmpty} from '../components/Filter';
import {GridList, GridListProps} from '../components/GridList';
import {ItemDetails, ItemDetailsProps} from '../components/ItemDetails';
import {ItemForm} from '../components/ItemForm';
import {
  RowCalendarList,
  RowCalendarListProps,
} from '../components/RowCalendarList';
import {
  MAIN_HEADER_HEIGHT,
  PARAM_KEY_CHECKED_IDS,
  PARAM_KEY_END_DATE,
  PARAM_KEY_ORDER,
  PARAM_KEY_PAGE,
  PARAM_KEY_SIZE,
  PARAM_KEY_SORT,
  PARAM_KEY_START_DATE,
} from '../consts';
import {NavContext, SetNav} from '../context';
import {getDateText} from '../dates/dates';
import {MaterialIcon} from '../icons/MaterialIcon';
import {GridLayout, Size} from '../layout/GridLayout';
import {borderColorLightest} from '../styles';
import {Actions} from '../types/Action';
import {Errors} from '../types/Errors';
import {FormFiles, FormValues} from '../types/Form';
import {Params} from '../types/Params';
import {Resource} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Component, ListItem, Schema} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {ToolButton} from '../types/ToolButton';
import {downloadByPost} from '../util';

type URLMatch = {
  appId: string;
};

type Props = URLMatch & {
  location: Location;
  setNav: SetNav;
};

type Status =
  | 'init'
  | 'loading'
  | 'loaded'
  | 'creating'
  | 'creating_ref'
  | 'creating_dialog'
  | 'editing'
  | 'editing_dialog'
  | 'batch_editing'
  | 'copying'
  | 'deleting'
  | 'requesting_app'
  | 'requesting_item'
  | 'requested'
  | 'importing'
  | 'exporting'
  | 'error'
  | 'fatal';

type State = {
  status: Status;
  errors: Errors;
  resourceList?: ResourceList;
  schema?: Schema;
  defaultParams?: Params;
  serverDefaultParams?: Params;
  focusedId: string;
  focused: Resource | null;
  newResource?: Resource;
  button?: ToolButton;
  buttonParams?: Params;
  sorterId?: string;
  sortOrderDesc?: boolean;
  filtered?: boolean;
  message: string;
  filterResourceList?: ResourceList;
  filterSchema?: Schema;
  filterShownInPanel?: boolean;
  checkedIds: Set<string>;
  checkedItems: ItemsMap;
  otherParams: {};
} & CustomLayout;

export class ListScreen extends React.Component<Props, State> {
  private readonly batchEditDialog: RefObject<Dialog>;
  private readonly ctx: ApiContext;
  private readonly appId: string;
  private readonly search: string;
  private readonly uiActions: UIActions;

  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'init',
      focusedId: '',
      focused: null,
      errors: {},
      message: '',
      customRows: {},
      customColumns: {},
      customComponents: {},
      checkedIds: new Set(),
      checkedItems: {},
      otherParams: {},
    };

    this.batchEditDialog = React.createRef();
    this.appId = props.appId;
    this.search = props.location.search;
    this.ctx = api.newContext();
    this.uiActions = this.buildUIActions();
  }

  componentDidMount = async () => {
    try {
      const clientDefaultParams = new URLSearchParams(this.search);
      const schema = await api.fetchSchema(
        this.ctx,
        this.appId,
        clientDefaultParams,
      );
      setDefaultParams(clientDefaultParams, schema);
      const sorter = getDefaultSorter(schema);
      setSortParams(clientDefaultParams, sorter);

      const resourceList = await api.list(
        this.ctx,
        this.appId,
        clientDefaultParams,
      );
      const filterResourceList = await this.fetchFilterResourceList(
        resourceList,
      );

      this.props.setNav(resourceList.nav);

      const layout = this.customLayout(schema) || {
        customRows: {},
        customColumns: {},
        customComponents: {},
      };

      const defaultParams = excludeCommonParams(resourceList.params);
      const serverDefaultParams = excludeKnownParams(
        defaultParams,
        clientDefaultParams,
      );

      this.setState({
        resourceList,
        defaultParams,
        serverDefaultParams,
        schema,
        filterResourceList: filterResourceList,
        filterSchema: filterResourceList?.schema,
        sorterId: sorter.id,
        sortOrderDesc: sorter.defaultOrder === 'desc',
        ...layout,
      });
    } catch (e) {
      this.handleError(e);
      this.setState({status: 'fatal'});
    }
  };

  componentWillUnmount() {
    this.ctx.abort();
  }

  customLayout(schema: Schema): CustomLayout | null {
    if (!isFolded()) {
      return null;
    }

    const {area, component} = selectAreaAndComponent<FilterProps>(
      schema.screen.components,
      'filter',
    );

    if (!area || !component) {
      return null;
    }

    return fold(this.state, area, component.toggle);
  }

  getAppId(): string {
    if (this.state.resourceList) {
      return this.state.resourceList.schema.id;
    }

    return this.appId;
  }

  getScreen() {
    if (!this.state.resourceList) {
      return null;
    }

    return this.state.resourceList.schema.screen;
  }

  fetchFilterResourceList = async (resourceList: ResourceList) => {
    const filterProps = selectComponent<FilterProps>(
      resourceList.schema.screen.components,
      'filter',
    );

    if (!filterProps || !filterProps.appId) {
      return;
    }

    const schema = await api.fetchSchema(this.ctx, filterProps.appId, {});
    const sorter = getDefaultSorter(schema);
    const params = new URLSearchParams(this.search);
    setSortParams(params, sorter);
    return await api.list(this.ctx, filterProps.appId, params);
  };

  onSort = async (button: ToolButton, sorter?: Sorter) => {
    const sorterId = (sorter || {}).id;

    if (!sorterId) {
      return;
    }

    const sortOrderDesc =
      this.state.sorterId === sorterId
        ? !this.state.sortOrderDesc
        : sorter!.defaultOrder === 'desc';

    const params = Object.assign({}, this.state.resourceList!.params, {
      [PARAM_KEY_SORT]: sorterId,
      [PARAM_KEY_ORDER]: sortOrderDesc ? 'desc' : 'asc',
    });

    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      params,
    );
    this.setState({
      resourceList,
      sorterId,
      sortOrderDesc,
    });
  };

  onChangeDateRange = async (start: Date, end: Date) => {
    if (!this.state.resourceList) {
      return;
    }

    const otherParams = {
      [PARAM_KEY_START_DATE]: getDateText(start),
      [PARAM_KEY_END_DATE]: getDateText(end),
    };

    const params: Params = {...this.state.resourceList.params, ...otherParams};

    const list = await api.list(
      this.ctx,
      this.state.resourceList.schema.id,
      params,
    );

    this.setState({
      resourceList: list,
      filterResourceList: list,
      otherParams: otherParams,
    });
  };

  onSelectRow = async (item: ResourceDetails) => {
    if (!item) {
      return;
    }

    const resId = item.id;
    const appId = item.schema_id || this.appId;

    const listProps = selectListComponent(this.getScreen()!.components);

    if (listProps && listProps.onSelectRow) {
      return this.doSelectRow(listProps.onSelectRow, appId, resId);
    }

    return await this.onSelectItem(item);
  };

  doSelectRow = async (
    onSelectRow: OnSelectRowProps,
    appId: string,
    resId: string,
  ) => {
    if (onSelectRow.type === 'go') {
      // eslint-disable-next-line no-template-curly-in-string
      const url = onSelectRow.url.replace('${id}', resId);
      window.location.assign(url);
      return;
    }
  };

  onSelectItem = async (item: ResourceDetails) => {
    if (!item) {
      return;
    }

    return await this.onSelectId(item.schema_id || this.appId, item.id);
  };

  onSelectId = async (appId: string, resId: string, query?: Query) => {
    this.setState({
      focusedId: resId,
    });

    const props = selectComponent<ItemDetailsProps>(
      this.getScreen()!.components,
      'item',
    );

    if (!props) {
      const params = makeParams(query);
      window.open(`/app/${appId}/${resId}${params}`);
      return;
    }

    this.setState({
      status: 'loading',
      errors: {},
    });

    if (!resId) {
      this.setState({
        status: 'error',
      });
      return;
    }

    const resource = await api.show(this.ctx, appId, resId, query);

    this.setState({
      focused: resource,
      status: 'loaded',
    });
  };

  onCopy = async () => {
    this.setState({
      status: 'loading',
    });

    const resource = await api.copy(
      this.ctx,
      this.state.focused!.schema.id,
      this.state.focused!.id,
    );

    this.setState({
      newResource: resource,
      status: 'copying',
    });
  };

  onCreate = async (button: ToolButton) => {
    if (button.openIn === 'dialog') {
      this.setState({
        status: 'creating_dialog',
        button: button,
        buttonParams: {...button.params},
      });
      return;
    }

    const props = selectComponent<ItemDetailsProps>(
      this.getScreen()!.components,
      'item',
    );

    const appId = button.itemType || this.getAppId();

    if (!props) {
      window.open(`/app/${appId}/new`);
      return;
    }

    this.setState({
      status: 'loading',
    });

    const params = new URLSearchParams(this.search);
    const data = {
      ...toMap(params),
      [PARAM_KEY_CHECKED_IDS]: [...this.state.checkedIds],
    };

    const resource = await api.newByPost(this.ctx, appId, data);

    this.setState({
      newResource: resource,
      status: 'creating',
      button: button,
    });
  };

  onCreateRef = async (button: ToolButton) => {
    const currentRes = this.state.focused!;
    const params = buildCreateRefParams(currentRes, button);

    if (button.openIn === 'dialog') {
      this.setState({
        status: 'creating_dialog',
        button: button,
        buttonParams: params,
      });
      return;
    }

    this.setState({
      status: 'loading',
    });

    const appId = button.itemType || this.appId;
    const resource = await api.new_(this.ctx, appId, params);

    this.setState({
      newResource: resource,
      status: 'creating_ref',
      button: button,
    });
  };

  onEdit = async (button: ToolButton) => {
    if (button.openIn === 'dialog') {
      this.setState({
        status: 'editing_dialog',
        button: button,
        buttonParams: undefined,
      });
      return;
    }

    this.setState({
      status: 'loading',
    });

    const resource = await api.edit(
      this.ctx,
      this.state.focused!.schema.id,
      this.state.focused!.id,
    );

    this.setState({
      focused: resource,
      status: 'editing',
    });
  };

  onDelete = async (button: ToolButton) => {
    this.setState({
      status: 'deleting',
      button: button,
    });
  };

  onShow = async () => {
    window.open(
      `/app/${this.state.focused!.schema.id}/${this.state.focused!.id}`,
    );
  };

  onExport = async (button: ToolButton) => {
    const appId = button.itemType || this.state.resourceList!.schema.id;
    return this.downloadList(appId, 'export');
  };

  onImport = async (button: ToolButton) => {
    this.setState({
      status: 'importing',
      button,
    });
  };

  onDownload = async (button: ToolButton) => {
    if (button.path) {
      //TODO params?
      window.open(button.path);
      return;
    }

    const appId = this.state.resourceList!.schema.id;
    return this.downloadList(appId, 'download', button.params);
  };

  onLink = async (button: ToolButton) => {
    window.open(button.path);
  };

  onDownloadItem = async (button: ToolButton) => {
    if (button.path) {
      window.open(button.path);
      return;
    }

    const appId = this.state.focused!.schema.id;
    const path = `${this.state.focused!.id}/download`;
    downloadByPost(`/app/${appId}/${path}`, button.params || {});
  };

  onAppRequest = async (button: ToolButton) => {
    this.setState({
      status: 'requesting_app',
      button,
    });
  };

  onRequestItem = async (button: ToolButton) => {
    await this.setState({
      status: 'requesting_item',
      button,
    });
  };

  onSaveCreate = async (
    itemType: string,
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    const resource = await api.create(
      this.ctx,
      itemType,
      values,
      files,
      relatedValues,
    );

    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      this.state.resourceList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
      focused: resource,
    });
  };

  onSaveCreateRef = async (
    itemType: string,
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    await api.create(this.ctx, itemType, values, files, relatedValues);

    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      this.state.resourceList!.params,
    );

    let focused = null;

    if (this.state.focused && this.state.focusedId) {
      focused = await api.show(
        this.ctx,
        this.state.focused.schema.id,
        this.state.focusedId,
      );
    }

    this.setState({
      status: 'loaded',
      resourceList,
      focused,
    });
  };

  onSaveEdit = async (
    values: FormValues,
    files: FormFiles,
    relatedValues: any,
  ) => {
    const resource = await api.update(
      this.ctx,
      this.state.focused!.schema.id,
      this.state.focused!.id,
      values,
      files,
      relatedValues,
    );

    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      this.state.resourceList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
      focused: resource,
    });
  };

  onAfterDelete = async () => {
    try {
      const resourceList = await api.list(
        this.ctx,
        this.state.resourceList!.schema.id,
        this.state.resourceList!.params,
      );

      this.setState({
        status: 'loaded',
        resourceList,
        focused: null,
      });

      return true;
    } catch (e) {
      this.handleError(e);
      return false;
    }
  };

  onRenderRow = (item: ResourceDetails, schema: Schema, listItem: ListItem) => {
    const iconColor = item.icon_color || schema.iconColor;
    const props = iconColor ? {initialsColor: iconColor} : null;
    const name = item.__name__ || item.name;

    if (!schema.screen || !listItem) {
      return (
        <Persona
          title={name}
          text={name}
          size={PersonaSize.size24}
          onRenderInitials={() => null}
          {...props}
        />
      );
    }

    const {iconName, primaryText, secondaryText, tertiaryText} = listItem;
    const text = item[primaryText] || name;
    const subText = item[secondaryText] || item[tertiaryText];

    const sizeProps =
      !iconName && !secondaryText ? {size: PersonaSize.size24} : {};

    return (
      <Persona
        title={text}
        text={text}
        secondaryText={subText}
        tertiaryText={tertiaryText}
        onRenderInitials={() => (
          <div
            style={{
              fontSize: 24,
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <MaterialIcon iconName={iconName} />
          </div>
        )}
        {...sizeProps}
        {...props}
      />
    );
  };

  onOpenSearch = () => {
    this.setState({filterShownInPanel: true});
  };

  onCloseSearch = () => {
    this.setState({filterShownInPanel: false});
  };

  onSearch = async (values: FormValues) => {
    const list = this.state.filterResourceList || this.state.resourceList;

    if (!list) {
      return;
    }

    const specifiedValues = ignoreEmpty(
      values,
      Object.keys(this.state.defaultParams || {}),
    );

    const filtered = Object.keys(specifiedValues).length > 0;
    // If filter is cleared, we use `this.appId` to show default list.
    const appId = filtered ? list.schema.id : this.appId;

    const merged = Object.assign(
      {},
      this.state.defaultParams,
      this.state.otherParams,
      this.getBasicListParams(list, appId),
      specifiedValues,
    );

    // Default parameters specified on the server side must be sent to the server even if the value is empty.
    // This is because the server will want to know that an empty value was specified on the server side.
    // On the other hand, default parameters specified on the client side do not need to be sent to the server side if they are empty.
    // There is no need to distinguish between parameters set automatically on the client side and parameters specified by the user.
    const params = ignoreEmpty(
      merged,
      Object.keys(this.state.serverDefaultParams || {}),
    );

    const resourceList = await api.list(this.ctx, appId, params);

    const state: Pick<
      State,
      'resourceList' | 'filtered' | 'filterResourceList'
    > = {
      resourceList,
      filtered,
    };

    if (filtered) {
      state.filterResourceList = resourceList;
    }

    this.setState(state);
  };

  onSearchAndClose = async (values: FormValues) => {
    await this.onSearch(values);
    this.onCloseSearch();
  };

  onResetSearch = async () => {
    this.setState({filtered: false});
  };

  onResetSearchAndClose = async () => {
    await this.onResetSearch();
    this.onCloseSearch();
  };

  onActionEnd = async () => {
    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      this.state.resourceList!.params,
    );

    this.setState({
      resourceList,
    });
  };

  onFold = async (state: CustomLayout) => {
    this.setState(state);
  };

  onUnfold = async (state: CustomLayout) => {
    this.setState(state);
  };

  onCheck = async (ids: Set<string>, checked: boolean) => {
    if (checked) {
      const checkedIds = new Set([...this.state.checkedIds, ...ids]);
      const checkedItems = buildCheckedItems(ids, this.state.resourceList);

      this.setState({
        checkedIds,
        checkedItems: {...this.state.checkedItems, ...checkedItems},
      });
      return;
    }

    const checkedIds = minus(this.state.checkedIds, ids);
    this.setState({checkedIds});
  };

  onBatchEdit = async () => {
    this.batchEditDialog.current!.showDialog();

    this.setState({
      status: 'batch_editing',
    });
  };

  onConfig = async (button: ToolButton) => {
    const schemaId = button.schemaId ?? this.getAppId();
    const url = buildConfigUrl(schemaId);
    window.location.assign(url);
    return;
  };

  getBasicListParams(list: ResourceList, targetAppId: string) {
    const curr = list.params;

    if (list.schema.id === targetAppId) {
      return {
        [PARAM_KEY_SORT]: curr[PARAM_KEY_SORT],
        [PARAM_KEY_ORDER]: curr[PARAM_KEY_ORDER],
        [PARAM_KEY_SIZE]: curr[PARAM_KEY_SIZE],
        [PARAM_KEY_PAGE]: 0,
      };
    }

    const schema =
      this.state.filterSchema?.id === targetAppId
        ? this.state.filterSchema
        : this.state.schema;
    const sorter = getDefaultSorter(schema);

    return {
      [PARAM_KEY_SORT]: sorter.id,
      [PARAM_KEY_ORDER]: sorter.defaultOrder,
    };
  }

  reloadList = async () => {
    const resourceList = await api.list(
      this.ctx,
      this.state.resourceList!.schema.id,
      this.state.resourceList!.params,
    );

    this.setState({
      status: 'loaded',
      resourceList,
    });
  };

  loadItem = async (schemaId: string, id: string) => {
    const res = await api.show(this.ctx, schemaId, id);

    this.setState({
      focused: res,
    });

    this.reloadList();
  };

  closeItem = async () => {
    this.setState({
      focused: null,
    });

    this.reloadList();
  };

  handleError(e: any) {
    if (e instanceof ApiError) {
      this.setState({
        errors: e.getMessageMap(),
      });
    }
  }

  resetStatus = () => {
    this.setState({status: 'loaded', errors: {}});
  };

  async downloadList(
    appId: string,
    path: string,
    optParams?: {[key: string]: any},
  ) {
    this.setState({status: 'exporting'});

    const params: Params = {
      ...this.state.resourceList!.params,
      ...optParams,
    };

    if (this.state.checkedIds.size > 0) {
      params.id = [...this.state.checkedIds];
    }

    delete params[PARAM_KEY_SIZE];
    delete params[PARAM_KEY_PAGE];

    try {
      await api.postJson(this.ctx, `/app/${appId}/${path}/check`, params);
    } catch (e) {
      this.handleError(e);
      return;
    }

    downloadByPost(`/app/${appId}/${path}`, params);
    this.resetStatus();
  }

  getButtonItemType(defaultValue: string) {
    if (!this.state.button || !this.state.button.itemType) {
      return defaultValue;
    }

    return this.state.button.itemType;
  }

  getButtonIconName(defaultValue: string) {
    if (!this.state.button || !this.state.button.iconName) {
      return defaultValue;
    }

    return this.state.button.iconName;
  }

  getButtonText(defaultValue: string) {
    if (!this.state.button || !this.state.button.name) {
      return defaultValue;
    }

    return this.state.button.name;
  }

  buildListButtons(buttons?: ToolButton[]) {
    if (!buttons) {
      return [];
    }

    const mappings: {[key: string]: any} = {
      create: this.onCreate,
      search: this.onOpenSearch,
      sort: this.onSort,
      download: this.onDownload,
      link: this.onLink,
      export: this.onExport,
      import: this.onImport,
      request: this.onAppRequest,
      batch_edit: this.onBatchEdit,
      config: this.onConfig,
    };

    const list = selectListComponent(this.getScreen()!.components);
    const sorters = list ? list.sorters : [];

    buttons.forEach((button) => {
      if (button.type === 'sort') {
        if (!button.sorters) {
          button.sorters = sorters;
        }

        button.currentSort = {
          sorterId: this.state.sorterId!,
          desc: this.state.sortOrderDesc!,
        };
      }

      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  buildItemButtons(buttons?: ToolButton[]) {
    if (!buttons) {
      return [];
    }

    const mappings: {[key: string]: any} = {
      create_ref: this.onCreateRef,
      edit: this.onEdit,
      delete: this.onDelete,
      copy: this.onCopy,
      show: this.onShow,
      download: this.onDownloadItem,
      request: this.onRequestItem,
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  buildFilterButtons(buttons?: ToolButton[]) {
    if (!buttons) {
      return [];
    }

    const mappings: {[key: string]: any} = {
      //TODO buttons for filter
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  getEmptyMessage(): string {
    if (this.state.filtered) {
      return '検索条件に一致するデータがありません';
    }

    return 'データがありません';
  }

  buildActions(): Actions {
    //TODO
    return {
      show: (args) => this.onSelectItem(args.item),
    };
  }

  buildUIActions(): UIActions {
    return new UIActions({
      close: this.closeItem,
      load: this.loadItem,
    });
  }

  renderListHeader(buttons?: ToolButton[], farButtons?: ToolButton[]) {
    return (
      <ListHeader>
        <Toolbar
          buttons={this.buildListButtons(buttons)}
          farButtons={this.buildListButtons(farButtons)}
        />
      </ListHeader>
    );
  }

  renderItemHeader(buttons: ToolButton[], farButtons: ToolButton[]) {
    return (
      <ItemHeader>
        <Toolbar
          buttons={this.buildItemButtons(buttons)}
          farButtons={this.buildItemButtons(farButtons)}
        />
      </ItemHeader>
    );
  }

  renderSimpleListBody(listItem: ListItem, tree: boolean) {
    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({
          resourceList,
        });
      },
      this.state.resourceList,
      this.state.defaultParams,
    );

    return (
      <ListBody>
        <SimpleList
          hideHeader={true}
          tools={{
            counter: !tree,
            pagination: true,
            numPaginationButtons: 5,
          }}
          resourceList={this.state.resourceList!}
          expandByDefault={this.state.filtered}
          onSelect={(item) => {
            this.onSelectRow(item);
          }}
          selectedIds={[this.state.focusedId]}
          tree={tree}
          onRenderRow={(item: ResourceDetails) =>
            this.onRenderRow(item, this.state.resourceList!.schema, listItem)
          }
          emptyMessage={this.getEmptyMessage()}
          {...pagerProps}
        />
      </ListBody>
    );
  }

  renderSimpleList(props: SimpleListProps) {
    if (!this.state.resourceList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons, props.farButtons)}
        {this.renderSimpleListBody(props.listItem, props.tree)}
      </ListContainer>
    );
  }

  renderGridListBody(props: GridListProps) {
    if (!props.columns || props.columns.length === 0) {
      return null;
    }

    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({
          resourceList,
        });
      },
      this.state.resourceList,
      this.state.defaultParams,
    );

    return (
      <ListBody>
        <GridList
          {...props}
          resourceList={this.state.resourceList!}
          onSelect={this.onSelectRow}
          selectedIds={[this.state.focusedId]}
          emptyMessage={this.getEmptyMessage()}
          sorters={getSorters(this.state.resourceList!.schema)}
          actions={this.buildActions()}
          onCheck={this.onCheck}
          checkedIds={this.state.checkedIds}
          {...pagerProps}
        />
      </ListBody>
    );
  }

  renderGridList(props: GridListProps) {
    if (!this.state.resourceList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons, props.farButtons)}
        {this.renderGridListBody(props)}
        {this.renderCheckedItems()}
      </ListContainer>
    );
  }

  renderRowCalendarList(props: RowCalendarListProps) {
    if (!this.state.resourceList) {
      return null;
    }

    return (
      <ListContainer>
        {this.renderListHeader(props.buttons)}
        {this.renderRowCalendarListBody(props)}
        {this.renderCheckedItems()}
      </ListContainer>
    );
  }

  renderRowCalendarListBody(props: RowCalendarListProps) {
    const params = this.state.resourceList!.params;
    const start = params['_start_date'];
    const end = params['_end_date'];

    if (typeof start !== 'string' || typeof end !== 'string') {
      return null;
    }

    const s = newDate(start);
    const e = newDate(end);

    if (!s || !e) {
      return null;
    }

    const dateRange = DateRange.fromStartEnd(s, e);

    const pagerProps = buildPagerProps(
      this.ctx,
      (resourceList: ResourceList) => {
        this.setState({
          resourceList,
        });
      },
      this.state.resourceList,
      this.state.defaultParams,
    );

    return (
      <ListBody>
        <RowCalendarList
          {...props}
          resourceList={this.state.resourceList!}
          items={this.state.resourceList!.list}
          events={this.state.resourceList!.relatedResources}
          dateRange={dateRange}
          onChangeDateRange={this.onChangeDateRange}
          onSelect={this.onSelectRow}
          onClickEvent={this.onSelectId}
          actions={this.buildActions()}
          onCheck={this.onCheck}
          checkedIds={this.state.checkedIds}
          {...pagerProps}
        />
      </ListBody>
    );
  }

  renderCheckedItems() {
    return (
      <CheckedItems
        isOpen={!isManipulating(this.state.status)}
        checkedIds={this.state.checkedIds}
        checkedItems={this.state.checkedItems}
        schemaId={this.appId}
        actions={this.buildActions()}
        onClear={() => {
          this.setState({checkedIds: new Set()});
        }}
      />
    );
  }

  renderForm(
    res: Resource,
    onSave: (
      values: FormValues,
      files: FormFiles,
      relatedValues: any,
    ) => Promise<void>,
    title: string,
    iconName: string,
  ) {
    const props = selectComponent<ItemDetailsProps>(
      res.schema.screen.components,
      'item',
    );

    if (!props) {
      return null;
    }

    const sections = extractSections(res.schema);

    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader iconName={iconName} text={title} />
        </ItemHeader>
        <ItemBody>
          <ItemForm
            ctx={this.ctx}
            resource={res}
            sections={sections}
            labelPosition={props.labelPosition}
            fieldSeparator={props.fieldSeparator}
            onCancel={() => {
              this.setState({status: 'loaded', errors: {}});
            }}
            onSave={onSave}
            actions={this.buildActions()}
          />
        </ItemBody>
      </ItemContainer>
    );
  }

  renderItem() {
    const {focused, newResource, status} = this.state;

    if (status === 'init') {
      return null;
    }

    if (status === 'loading') {
      return <Spinner />;
    }

    if (status === 'editing') {
      return this.renderForm(
        focused!,
        this.onSaveEdit,
        '編集',
        'edit-outlined',
      );
    }

    if (status === 'creating' || status === 'creating_ref') {
      const itemType = this.getButtonItemType(this.getAppId());
      const iconName = this.getButtonIconName('add');
      const text = this.getButtonText('作成');
      const onSave =
        status === 'creating' ? this.onSaveCreate : this.onSaveCreateRef;

      return this.renderForm(
        newResource!,
        (values: FormValues, files: FormFiles, relatedValues: any) => {
          return onSave(itemType, values, files, relatedValues);
        },
        text,
        iconName,
      );
    }

    if (status === 'copying') {
      return this.renderForm(
        newResource!,
        (values: FormValues, files: FormFiles, relatedValues: any) => {
          return this.onSaveCreate(
            newResource!.schema.id,
            values,
            files,
            relatedValues,
          );
        },
        'コピー',
        'file_copy-outlined',
      );
    }

    if (focused) {
      const props = selectComponent<ItemDetailsProps>(
        focused.schema.screen.components,
        'item',
      );

      if (!props) {
        return null;
      }

      const sections = extractSections(focused.schema);

      return (
        <>
          <ItemContainer>
            {this.renderItemHeader(props.buttons, props.farButtons)}
            <ItemBody>
              <ItemDetails
                resource={focused}
                sections={sections}
                labelPosition={props.labelPosition}
                fieldSeparator={props.fieldSeparator}
                actions={this.buildActions()}
              />
            </ItemBody>
          </ItemContainer>
        </>
      );
    }

    return null;
  }

  renderDeleteDialog() {
    if (!this.state.focused) {
      return null;
    }

    return (
      <DeleteDialog
        ctx={this.ctx}
        shown={this.state.status === 'deleting'}
        appId={this.state.focused.schema.id}
        resId={this.state.focused.id}
        onAfter={this.onAfterDelete}
        onClose={this.resetStatus}
      />
    );
  }

  renderAppRequestDialog() {
    return (
      <AppRequestDialog
        ctx={this.ctx}
        shown={this.state.status === 'requesting_app'}
        appId={this.state.resourceList?.schema.id}
        params={this.state.button?.params}
        onAfterRequest={() => {}}
        onClose={(resp) => {
          this.uiActions.doAction(resp);
          this.resetStatus();
        }}
        actions={this.buildActions()}
      />
    );
  }

  renderRequestDialog() {
    const target = this.state.focused;

    if (!target) {
      return null;
    }

    return (
      <RequestDialog
        ctx={this.ctx}
        shown={this.state.status === 'requesting_item'}
        target={{
          schemaId: target.details.schema_id,
          resId: target.details.id,
        }}
        params={this.state.button?.params}
        onAfterRequest={() => {}}
        onClose={(resp) => {
          this.uiActions.doAction(resp);
          this.resetStatus();
        }}
        actions={this.buildActions()}
      />
    );
  }

  renderImportDialog() {
    return (
      <ImportDialog
        ctx={this.ctx}
        shown={this.state.status === 'importing'}
        appId={
          this.state.button?.itemType || this.state.resourceList?.schema.id
        }
        fileTypes={this.state.button?.fileTypes}
        onClose={this.resetStatus}
        onComplete={this.reloadList}
      />
    );
  }

  renderBatchEditDialog() {
    return (
      <Dialog
        ref={this.batchEditDialog}
        modal={true}
        title={'一括編集'}
        maxWidth="100%">
        {this.renderBatchEditDialogContent()}
      </Dialog>
    );
  }

  renderBatchEditDialogContent() {
    if (!this.state.resourceList) {
      return <Spinner />;
    }

    const load = this.state.status === 'batch_editing';

    return (
      <DialogContents>
        <ErrorMsg messages={this.state.errors['_']} />
        <BatchEditForm
          ctx={this.ctx}
          appId={this.state.resourceList!.schema.id}
          ids={this.state.checkedIds}
          actions={this.buildActions()}
          onAfterSave={this.reloadList}
          onCancel={() => {
            this.batchEditDialog.current!.closeDialog();
            this.resetStatus();
          }}
          load={load}
        />
      </DialogContents>
    );
  }

  renderItemFormDialog() {
    const itemType = this.getButtonItemType(this.getAppId());
    // const iconName = this.getButtonIconName('add');
    const text = this.getButtonText('作成');

    return (
      <ItemFormDialog
        ctx={this.ctx}
        shown={
          this.state.status === 'editing_dialog' ||
          this.state.status === 'creating_dialog'
        }
        actions={this.buildActions()}
        appId={itemType}
        isNew={this.state.status === 'creating_dialog'}
        resId={this.state.focused?.id}
        checkedIds={this.state.checkedIds}
        params={this.state.buttonParams}
        title={text}
        onAfter={this.reloadList}
        onClose={this.resetStatus}
      />
    );
  }

  renderExportDialog() {
    return (
      <ExportDialog
        ctx={this.ctx}
        shown={this.state.status === 'exporting'}
        messages={this.state.errors['_']}
        onClose={this.resetStatus}
      />
    );
  }

  renderFilter(area: string, props: FilterProps) {
    const list = this.state.filterResourceList || this.state.resourceList;

    if (!list) {
      return null;
    }

    return (
      <Filter
        ctx={this.ctx}
        resourceList={list}
        scopes={list.schema.scopes}
        filters={props.filters}
        fields={list.schema.fields}
        relatedSchemas={list.relatedSchemas}
        defaultParams={this.state.defaultParams}
        onSearch={this.onSearch}
        onReset={this.onResetSearch}
        actions={this.buildActions()}
        buttons={this.buildFilterButtons(props.buttons)}
        farButtons={this.buildFilterButtons(props.farButtons)}
        toggleArea={area}
        toggle={props.toggle}
        onClickFoldButton={this.onFold}
        customRows={this.state.customRows}
        customColumns={this.state.customColumns}
        customComponents={this.state.customComponents}
      />
    );
  }

  renderFilterInPanel() {
    return (
      <div>
        <Panel
          className={'filter-panel'}
          hasCloseButton={true}
          closeButtonAriaLabel="Close"
          styles={{
            content: {
              padding: 0,
            },
            commands: {
              marginTop: 0,
              height: MAIN_HEADER_HEIGHT,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderBottom: `1px solid ${borderColorLightest}`,
            },
            closeButton: {
              marginRight: 4,
            },
          }}
          isOpen={this.state.filterShownInPanel}
          onDismiss={this.onCloseSearch}>
          {this.renderFilterInPanelContents()}
        </Panel>
      </div>
    );
  }

  renderFilterInPanelContents() {
    const list = this.state.filterResourceList || this.state.resourceList;

    if (!list) {
      return null;
    }

    return (
      <Filter
        ctx={this.ctx}
        resourceList={list}
        scopes={list.schema.scopes}
        fields={list.schema.fields}
        relatedSchemas={list.relatedSchemas}
        defaultParams={this.state.defaultParams}
        onSearch={this.onSearchAndClose}
        onReset={this.onResetSearchAndClose}
        actions={this.buildActions()}
      />
    );
  }

  renderDisplayUnfoldBar(area: string, props: DisplayUnfoldBarProps) {
    return (
      <DisplayUnfoldBar
        area={props.area}
        toggle={props.toggle}
        onClick={this.onUnfold}
        customRows={this.state.customRows}
        customColumns={this.state.customColumns}
        customComponents={this.state.customComponents}
      />
    );
  }

  renderComponents(components: {[key: string]: any}) {
    const result: {[key: string]: any} = {};

    Object.keys(components).forEach((areaKey) => {
      result[areaKey] = this.renderComponent(areaKey, components[areaKey]);
    });

    return result;
  }

  renderComponent(area: string, component: Component) {
    switch (component.type) {
      case 'filter':
        return this.renderFilter(area, component as FilterProps);
      case 'grid-list':
        return this.renderGridList(component as GridListProps);
      case 'simple-list':
        return this.renderSimpleList(component as SimpleListProps);
      case 'row-calendar-list':
        return this.renderRowCalendarList(component as RowCalendarListProps);
      case 'item':
        return this.renderItem();
      case 'display-unfold-bar':
        return this.renderDisplayUnfoldBar(
          area,
          component as DisplayUnfoldBarProps,
        );
      default:
        return null;
    }
  }

  renderFatalError() {
    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader
            iconName={'Error'}
            type={'error'}
            text={this.state.errors['_']?.join(' ') || 'Error'}
          />
        </ItemHeader>
        <ItemBody />
      </ItemContainer>
    );
  }

  render() {
    if (this.state.status === 'fatal') {
      return this.renderFatalError();
    }

    if (!this.state.resourceList) {
      return <Spinner />;
    }

    const screen = this.getScreen();

    if (!screen) {
      return false;
    }

    const columns = mergeSizes(screen.columns, this.state.customColumns);
    const rows = mergeSizes(screen.rows, this.state.customRows);
    const components = mergeComponents(
      screen.components,
      this.state.customComponents,
    );

    return (
      <>
        <GridLayout
          areas={screen.areas}
          columns={columns}
          rows={rows}
          components={this.renderComponents(components)}
          styles={screen.styles}
        />
        {this.renderAppRequestDialog()}
        {this.renderImportDialog()}
        {this.renderBatchEditDialog()}
        {this.renderDeleteDialog()}
        {this.renderRequestDialog()}
        {this.renderItemFormDialog()}
        {this.renderExportDialog()}
        {this.renderFilterInPanel()}
      </>
    );
  }
}

function mergeSizes(sizes: Size[], custom: {[key: string]: (Size | null)[]}) {
  if (Object.keys(custom).length === 0) {
    return sizes;
  }

  const merged = [...sizes];

  for (let customSizes of Object.values(custom)) {
    for (let i = 0; i < customSizes.length; i++) {
      const customSize = customSizes[i];

      if (customSize) {
        merged[i] = customSize;
      }
    }
  }

  return merged;
}

function mergeComponents(
  components: {[key: string]: Component},
  custom: {[key: string]: Component},
) {
  if (Object.keys(custom).length === 0) {
    return components;
  }

  const merged: {[key: string]: Component} = {...components};

  for (let [key, comp] of Object.entries(custom)) {
    merged[key] = comp;
  }

  return merged;
}

export function ListScreenContainer(): JSX.Element | null {
  const params = useParams<URLMatch>();
  const {setNav} = useContext(NavContext);
  const location = useLocation();

  return <ListScreen {...params} setNav={setNav} location={location} />;
}

function getSorters(s: Schema): Sorter[] | undefined {
  const list = selectListComponent(s.screen.components);

  if (!list) {
    return [];
  }

  return list.sorters;
}

function getDefaultSorter(s?: Schema): Sorter {
  if (!s) {
    return getFallbackDefaultSorter();
  }

  const sorters = getSorters(s);

  if (sorters) {
    for (let s of sorters) {
      if (s.default) {
        return s;
      }
    }
  }

  return getFallbackDefaultSorter();
}

function getFallbackDefaultSorter(): Sorter {
  return {
    id: 'updated_at',
    label: '更新日時',
    visible: true,
    default: true,
    defaultOrder: 'desc',
    currentOrder: 'desc',
  };
}

function toMap(params: URLSearchParams): {[key: string]: string[]} {
  const m: {[key: string]: string[]} = {};

  for (let key of params.keys()) {
    m[key] = params.getAll(key);
  }

  return m;
}

function isManipulating(status: Status): boolean {
  return [
    'creating',
    'creating_ref',
    'creating_dialog',
    'editing',
    'editing_dialog',
    'batch_editing',
    'copying',
    'deleting',
    'requesting_app',
    'requesting_item',
    'importing',
    'exporting',
  ].includes(status);
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListHeader = styled.div``;

const ListBody = styled.div`
  height: 100%;
  overflow: auto;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemHeader = styled.div``;

const ItemBody = styled.div`
  height: 100%;
  overflow: auto;
`;

const DialogContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;
