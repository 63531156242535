import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {ButtonWidget} from '../ButtonWidget';
import {LogoButton} from './LogoButton';
import {MultipleItemSelector} from './MultipleItemSelector';
import {ScanData} from './ScanData';
import {
  Body,
  ButtonContainer,
  Buttons,
  calcButtonWidth,
  calcWidth,
  DataContainer,
  Header,
  ItemsContainer,
  useHeaderStyle,
} from './UsersAndThings';

type Props = {
  data: ScanData;
  size: number;
  scheduleLabel: string;
  scheduleNameFieldId: string;
};

export function ScheduleList(props: Props): JSX.Element {
  useEffect(() => {
    props.data.listSchedule();
  }, [props.data]);

  const intl = useIntl();
  const headerStyle = useHeaderStyle(props.size);
  const width = calcWidth(props.size);
  const {min, max} = calcButtonWidth(props.size);

  return (
    <>
      <DataContainer>
        <ItemsContainer style={{flexGrow: 1}} width={width}>
          <Header style={headerStyle}>{props.scheduleLabel}</Header>
          <Body size={props.size}>
            <MultipleItemSelector
              checkedIds={props.data.getScheduleIds()}
              items={props.data.getSchedules()}
              onChecked={props.data.setScheduleIds}
              size={props.size}
              nameFieldId={props.scheduleNameFieldId}
            />
          </Body>
        </ItemsContainer>
      </DataContainer>
      <Buttons>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.goBack}
            label={intl.formatMessage({
              id: 'Widget.Scan.Action.Back',
              defaultMessage: 'Back',
            })}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            styles={{
              container: {justifyContent: 'start'},
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.doOut}
            label={intl.formatMessage({
              id: 'Widget.Scan.Action.Submit',
              defaultMessage: 'Submit',
            })}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            primary={true}
            disabled={
              !props.data.canSendRequest() ||
              props.data.getScheduleIds().length === 0
            }
            styles={{
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <ButtonContainer>
          <ButtonWidget
            onClick={props.data.doOut}
            label={intl.formatMessage({
              id: 'Widget.Scan.Action.SubmitWithoutSchedule',
              defaultMessage: 'Submit w/o Schedule',
            })}
            size={props.size}
            minWidth={min}
            maxWidth={max}
            primary={true}
            disabled={
              !props.data.canSendRequest() ||
              props.data.getScheduleIds().length > 0
            }
            styles={{
              button: {flexGrow: 1},
            }}
          />
        </ButtonContainer>
        <LogoButton />
      </Buttons>
    </>
  );
}
