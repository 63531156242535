import React from 'react';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {ButtonWidget} from '../widgets/ButtonWidget';
import {widgetProps} from '../widgets/common';
import {useActionAndArgs} from './hooks/useActionAndArgs';

type Props = {
  schema: Schema;
  field: Field;
  item: ResourceDetails;
  actions: Actions;
};

export function ButtonField(props: Props): JSX.Element | null {
  const iconName = widgetProps<string>(props.field, 'icon_name', '');
  const label = widgetProps<string>(props.field, 'label', '');
  const size = widgetProps<number | undefined>(props.field, 'size', undefined);
  const width = widgetProps<number | undefined>(
    props.field,
    'width',
    undefined,
  );

  const {action, args} = useActionAndArgs(
    props.field,
    props.schema,
    props.item,
    props.actions,
    'on_press',
    '',
  );

  const onClick = () => {
    action(args);
  };

  return (
    <ButtonWidget
      iconName={iconName}
      label={label}
      onClick={onClick}
      size={size}
      width={width}
    />
  );
}
