import {materialIconClassName} from '../../icons/materialIcons';
import {ToolButton} from '../../types/ToolButton';
import {ICommandBarItemProps} from '@fluentui/react';
import {buttonTemplates} from './ButtonTemplates';
import './buttons.css';

export function buildItems(buttons: ToolButton[]): ICommandBarItemProps[] {
  const templates = buttonTemplates;
  const builtItems: ICommandBarItemProps[] = [];

  buttons.forEach((button, i) => {
    const tpl = templates[button.type];

    if (!tpl || !tpl.isValid(button)) {
      return;
    }

    const item = convertToItem(button, i);
    const builtItem = Object.assign({}, tpl, item);
    setClassName(builtItem);

    if (builtItem.build) {
      builtItem.build(builtItem, button);
    }

    builtItems.push(builtItem);
  });

  return builtItems;
}

function convertToItem(button: ToolButton, i: number): ICommandBarItemProps {
  const item: ICommandBarItemProps = {
    key: button.type + '_' + i,
  };

  if (button.name) {
    item.text = button.name;
  }

  if (button.iconName) {
    item.iconProps = {
      iconName: button.iconName,
    };
  }

  if (button.onClick) {
    item.onClick = () => {
      button.onClick(button);
    };
  }

  return item;
}

function setClassName(builtItem: ICommandBarItemProps) {
  if (
    builtItem.iconProps &&
    builtItem.iconProps.iconName &&
    !builtItem.iconProps.className
  ) {
    builtItem.iconProps.className = materialIconClassName(
      builtItem.iconProps.iconName,
      'eapp-toolbar-button',
    );
  }
}
