import {Field} from '../types/Field';

export function widgetProps<T = any>(field: Field, key: string, def: T): T {
  if (!field.widgetProps) {
    return def;
  }

  const v = field.widgetProps[key];

  if (v === undefined) {
    return def;
  }

  return v as T;
}

export type textDecoratorFn = (s: string) => string;

export function digitSeparator(num: string): string {
  const parts = String(num).split('.');
  const separated = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  if (parts.length > 1) {
    return separated + '.' + parts[1];
  }

  return separated;
}

function asis(s: string): string {
  return s;
}

export function textDecorator(digit?: boolean): textDecoratorFn {
  if (!digit) {
    return asis;
  }

  return digitSeparator;
}

export function selectStringValue(v: any, decorator?: textDecoratorFn): string {
  if (v === undefined || v === null) {
    return '';
  }

  if (Array.isArray(v)) {
    return v.map((x) => selectStringValue(x, decorator)).join(', ');
  }

  if (!decorator) {
    return String(v);
  }

  return decorator(String(v));
}

export type AutoComplete = 'new-password' | 'off';
