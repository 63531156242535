import * as React from 'react';
import {CSSProperties} from 'react';
import styled from 'styled-components';
import {ClearButton} from './common/ClearButton';
import {DateSelector} from './common/DateSelector';
import {formatDate, formatDateUTC, toDate} from './common/dateutil';

type Props = {
  value: any;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (dateStr: string) => void;
  style?: CSSProperties;
  hideClearButton?: boolean;
};

export function DateWidget(props: Props) {
  const onSelectDate = (date?: Date | null) => {
    const value = formatDateUTC(date);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  if (props.readOnly) {
    const date = toDate(props.value);
    const dateStr = formatDate(date);
    return <Container>{dateStr}</Container>;
  }

  const onClear = () => {
    onSelectDate();
  };

  return (
    <Container style={props.style}>
      <DateSelector
        value={toDate(props.value)}
        onSelectDate={onSelectDate}
        placeholder={props.placeholder}
      />
      {props.hideClearButton ? null : (
        <ClearButton
          onClick={onClear}
          styles={{root: {marginLeft: '0.5rem'}}}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  max-width: 300px;
`;
